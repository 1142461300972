import React from "react";
// elements
import AltPage from "components/layouts/AltPage"
import ContentDisplaySimple from "components/elements/ContentDisplaySimple"

// elements
import { DealerAgreementMap, DealerAgreementPages, submitUrl, responses } from 'views/form-content/DealerAgreement';
import formatValues from 'views/form-content/DealerAgreement';
import CustomStepperForm from "components/forms/form-layouts/CustomStepperForm";
//import { DealerAgreementMap, DealerAgreementPages, submitUrl, responses } from './form-maps/DealerAgreementMapTest';


type Props = {
}
const x = 1;


export default function DealerAgreementForm(props: Props) {
  const imgUrl = process.env.PUBLIC_URL + "/img/documents2.jpg";
  const title = 'Dealer Agreement';
  const body = <CustomStepperForm formPages={DealerAgreementPages} 
  formMap={DealerAgreementMap} 
  formatValues={formatValues} 
  submitUrl={submitUrl}
  orientation="horizontal"
  formWidth={{md: '100%', lg: '60%'}}
  responses={responses}
   />;

  return (
    <>
    <AltPage useDefaultNavbar={false} img={imgUrl} content={<ContentDisplaySimple
                          img={imgUrl}
                          width={{xs: '90vw', md: '80vw', lg: '80vw'}}
                          title={title}
                          body={body} />} />
    
    </>
  );
}
