import * as React from 'react';

//react-hook-form
import { useWatch } from "react-hook-form";

import StepItemInput from './StepItemInput';


interface Props {
    meta?: any,
    values?: any,
    trigger?: any,
    control?: any,
    name?: any,
    register?: any,
    form?: any,
    watchName?: any,
    stepItem?: any,
    item?: any,
    option?: any,
    watcher?: any,
    defaultValue?: any,
    triggerValue?: any,
    children?: any,
    triggerKey?: any,
    step?: any,
    errors?: any,
}


export default function StepWatcher({children, register, errors, control, watchName, defaultValue, triggerValue, triggerKey, step, stepItem}: Props) {  
  const result = useWatch({control, name: watchName, defaultValue: defaultValue});

  return(<>
    {
        step!==undefined&&((result===triggerValue)||(triggerKey===false))&&<StepItemInput errors={errors} item={
          (step||{})[triggerKey||result]||[]
        } />
    }
    </>
  )
}
