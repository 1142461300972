import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import { Link } from "react-router-dom";

// elements
import ProfileNav from "components/navbars/ProfileNav"

// styling


type Props = {
    topTheme: {
      bgColor: string,
      color: string,
      logo: boolean,
      shadow: number
    }
    handlePage: any,
    currentPage: any,
    useDefault: boolean
}
type NavLinkProps = {
  page: {
    name: string,
    href: string,
    value: string,
    style?: string
  },
  handlePage: any,
  currentPage: any,
  color?: any
}

const navThemes = {
  "transparent": {
    bgColor: "transparent",
    color: "white",
    leftButtonColor: "white",
    rightButtonColor: "white",
    shadow: 0,
  },
  "white": {
    bgColor: "white",
    color: "black",
    leftButtonColor: "black",
    rightButtonColor: "primary",
    shadow: 1,
  },
}


const leftButtons = [
{name: 'Customers', href: '/index/customers', value: "customers",},
{name: 'Dealers', href: '/index/dealers', value: "dealers",},
{name: 'Questions', href: '/index/faq', value: "faq",},
//{name: 'About Us', href: '/aboutus', value: "",},
{name: 'Contact Us', href: '/index/contactus', value: "contactus",},
];
const rightButtons = [
  {name: 'Pay Now', href: 'https://www.paynearme.com/baysidecredit', value: "", variant: "contained"},
  
  ];
const allowTranslate = false;
const translateButton = {name: 'Español', href: '', value: "spanish", variant: "outlined"};

const getNavStatus: any = (minimumChangeHeight: number, defaultNavTheme: any, topNavTheme: any) => {
  if (
    document.documentElement.scrollTop > minimumChangeHeight ||
    document.body.scrollTop > minimumChangeHeight
  ) {
    return defaultNavTheme;
  }
  else {
    return topNavTheme;
  }
}


const StyledNavButton: NavLinkProps = ({page, handlePage, currentPage, color, variant, ...props}) => {
  const isExternalUrl = page.href.startsWith("http");
  return(
    <Button
        component={isExternalUrl? "a" : Link}
        to={page.href}
        href={page.href}
        onClick={isExternalUrl ? void(0) : (e) => handlePage(page.value)}
        key={page.name}
        variant={variant}
        color={color}
        sx={{
        display: 'block', pl: {sm: 1}, pr: {sm: 1},
        }}><Typography sx={{textDecoration: 'none', textTransform: 'uppercase', 
                        fontSize: {sm: '1.0rem'},
                        fontWeight: page.value===currentPage ? 'bold': 'none'
          }}>
         {page.name}
         </Typography>
    </Button>
  );
};

const StyledMenuItem: NavLinkProps = ({page, handlePage, ...props}) => {
  const isExternalUrl = page.href.startsWith("http");
  return(
    <MenuItem
        component={isExternalUrl? "a" : Link}
        to={page.href}
        href={page.href}
        onClick={isExternalUrl ? void(0) : () => handlePage(page.value)}
        key={page.name}>
        <Typography textAlign="center">{page.name}</Typography>
    </MenuItem>
  );
};


export default function Navbar(props: Props) {
  const showProfileNav = false;
  const logoUrl = "/BaysideLogo-B.png";
  const navbarChangeHeight = 10;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [navStatus, setNavStatus] = React.useState(getNavStatus(navbarChangeHeight, "white", "transparent"));

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  React.useEffect(() => {
    const changeTheme = () => setNavStatus(getNavStatus(navbarChangeHeight, "white", "transparent"));

    window.addEventListener("scroll", changeTheme);
    return function cleanup() {
      window.removeEventListener("scroll", changeTheme);
    };
  }, []);



  return (
    <AppBar position="fixed"
            sx={{background: navThemes[navStatus].bgColor, color: navThemes[navStatus].color, boxShadow: navThemes[navStatus].shadow,
                height: '15vh', }}>
    <Box sx={{height: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start'}}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <a href="/"><Box
              component="img"
              sx={{
                height: '7vh',
                pl: {sm: 1}
              }}
              alt="Bayside Credit"
              src={logoUrl}
          /></a>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ ml: 0, mr: 1, display: { xs: 'none', md: 'flex' },
            textTransform: 'capitalize', fontWeight: 'bold',
            }}>
          </Typography>

          <Box sx={{ height: '100%', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {leftButtons.map((page: any) => (
                <StyledMenuItem page={page} handlePage={props.handlePage} currentPage={props.currentPage} />
              ))}
              
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            
          </Typography>
          <Box sx={{ ml: 1, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {leftButtons.map((page: any) => (
              <StyledNavButton page={page} handlePage={props.handlePage} currentPage={props.currentPage} 
              color={navThemes[navStatus].leftButtonColor} />
          ))}          
          </Box>


          <Stack direction="row" spacing={1} sx={{ flexGrow: 0, }}>
          {rightButtons.map((page: any) => (
              <StyledNavButton page={page} handlePage={props.handlePage} currentPage={props.currentPage} 
              variant={page.variant}
              color={navThemes[navStatus].rightButtonColor} />
              
          ))}    
          {allowTranslate&&<><Divider orientation="vertical" flexItem />
          <StyledNavButton page={translateButton} handlePage={props.handlePage} currentPage={props.currentPage} 
              variant={translateButton.variant}
              color={navThemes[navStatus].rightButtonColor} /></>}
          </Stack>
          

           {showProfileNav ? <ProfileNav /> : ""}
        </Toolbar>
      </Container>
      </Box>
    </AppBar>
  );
};
