/*

All fields required unless stated otherwise.

=[Company Information]=
Company Name
DBA
Address
Phone
Fax
Email
Cell
Landlord Name
Landlord Phone
Effective date of current lease
Expiration date
Corporation Type [dropdown of Corporation, Sole Proprietor, LLC]
Federal Tax ID Number
Date Established
DealerTrack/RouteOne ID or Fax


=[Inventory]= (Optional)
Average # of vehicles in inventory
% Inventry owned and paid (titles in hand)
# Inventory on consignment
% Inventory purchased on floor plans (auction or otherwise)
Average # of Unit Sales Per month

=[Owners (+, max 2)]=
Name
Title
Address
SSN
DOB

=[Bank Reference]=
Bank Name
Account #
Account Established Date (Optional)
Average Balance (Optional)
Bank Contact Name (Optional)
Phone Number (Optional)

=[Authorized Signers (+, max 5)]=
Name

=[Payment]=
Transaction Authorization Method (checkboxes): Phone, Fax, Email
At least 1: ACH, Debit
[ACH: Account Name, Bank Name, Bank Acct #, Routing #]
[Debit: Card Name, Card #, Expiration, CVV, Billing Address]

=[Optional]=
Permit #
Attorney-in-Fact
Top 3 Subprime lender relations by volume (+, max 3)



*/

// icons
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
//import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoadingDisplay from "components/elements/LoadingDisplay"

import { flattenValues } from "components/forms/form-submit/FormatSubmit";


export const DealerAgreementPages = ["Company Information", 
"Owner(s) Information", 
"Payment Information", 
"Attestation"
  ];

export const recipients = [];
export const buildUrl = process.env.REACT_APP_API_URL+"/eversign/get?template_id=dealeragreement";
export const submitUrl = process.env.REACT_APP_API_URL+"/eversign/send?template_id=dealeragreement";


export const responses = {
  "success": {
    icon: <CheckIcon />,
    title: "Document Sent",
    body: <Stack sx={{border: '0px solid green', alignItems: 'center', maxWidth: 800}}>
    <Typography sx={{textAlign: 'justify'}}>Thank you for completing the Bayside Credit Dealer Application. Please check the email(s) provided and sign the dealer agreement.
    <br/><br/>We will also require the following supporting documents to complete your loan application (send to <a href="mailto:dealers@bayside-credit.com">dealers@bayside-credit.com</a>):
    <ul>
      <li>Copy of driver’s license for all dealer principals</li>
      <li>Copy of DMV Dealer License</li>
      <li>Copy of Dealer Bond</li>
      <li>Copy of Sales Tax Permit</li>
      <li>Most recent 3 months business bank statements</li>
    </ul>
    Once all required documents have been received, your application will be reviewed for final approval. If you have any questions in the meantime, please contact your Bayside Credit Relationship Manager.</Typography>
    </Stack>
  },
  "error": {
    icon: <ErrorOutlineIcon />,
    title: "Error",
    body: "Something went wrong while creating the document. Please try again."
  },
  "loading": {
    icon: null,
    title: "Loading",
    body: <LoadingDisplay />
  }
}


export default function formatValues(values: any) {
  const corporationCheckbox = {[`Checkbox ${values['Corporation Type']}`]: true};
  const sourceCheckbox = {[`Checkbox ${values['Source']}`]: true};
  const tam = values['Transaction Authorization Methods'];
  const transactionAuthorizationCheckbox = tam&&Object.entries(values['Transaction Authorization Methods']).reduce((prev, [k, v], i)=> {
    prev[`Checkbox ${k}`] = v;
    return prev
  }, {});
  // single source
  if (values['Source'] === 'Route One') {
    values['Dt Id'] = '';
  }
  else if (values['Source'] === 'Dealer Track') {
    values['Ro Id'] = '';
  }
  // list item input
    const subprime = flattenValues(values['Sub-Prime']);
    const auth_signers = flattenValues(values['Authorized Signer']);
    console.log("SUBPRIME", subprime)
    console.log("auth signers", auth_signers)
    //item input
    const signers = values['Owner'].reduce((prev, curr, i)=> {
      prev.push({
        "name": curr["Name"],
        "email": curr["Email"]
      })
      return prev;
    }, []);
    //delete values['Owner'];
    console.log("SIGNERS!!! ", signers);
    values = {...values, 
      "signers": signers,
      ...corporationCheckbox, ...sourceCheckbox, 
      ...transactionAuthorizationCheckbox,
      "Sub-Prime": subprime['Name'],
      "Authorized Signer": auth_signers['Name']
    };
  return values;
}

export const DealerAgreementMap = {
  "Company Information": [
    {
      label: "Legal Company Name",
      required: true
    },
    {
      label: "DBA",
      key: "Dba",
      required: true
    },
    {
      label: "Address",
      type: "address",
      required: true
    },
    {
      label: "Phone",
      type: "tel",
      required: true
    },
    {
      label: "Fax",
      type: "tel"
    },
    {
      label: "Cell",
      type: "tel"
    },
    {
      label: "Landlord Name",
    },
    {
      label: "Landlord Phone",
      type: "tel"
    },
    {
      label: "Effective Date of Current Lease",
      key: "Effective",
      type: "date"
    },
    {
      label: "Expiration Date",
      key: "Expiration",
      type: "date"
    },
    {
      label: "Federal Tax ID Number",
      key: "Fed Tax Id",
      type: "taxid",
      required: true
    },
    {
      label: "Sales Tax Permit Number",
      key: "Permit Number",
      required: true
    },
    {
      label: "Established Date",
      key: "Established",
      type: "date"
    },
    {
      label: "Corporation Type",
      type: "radio",
      required: true,
      defaultValue: 0,
      options: ["Corporation", "Sole Proprietor", "LLC"]
    },
    {
      label: "Source",
      type: "dropdown",
      required: true,
      defaultValue: 0,
      options: ["Dealer Track", "Route One", "Fax"],
      step: {
        "Dealer Track": [
          {
            label: "Dealer Track Id",
            key: "Dt Id"
          }
        ],
        "Route One": [
          {
            label: "Route One Id",
            key: "Ro Id"
          }
        ]
      }
    },
    {
      label: "Subprime Lender",
      key: "Sub-Prime",
      description: "Top Subprime Lenders by Volume",
      type: "listitem",
      max: 3,
      defaultValue: [{}],
      fields: [
        {
          label: "Name"
        }
      ]
    },
    {
      label: "Average # of Vehicles in Inventory",
      key: "Avg Inv"
    },
    {
      label: "Percent Inventory Owned and Paid (Titles in Hand)",
      key: "Percent Owned",
      type: "percent"
    },
    {
      label: "Percent Inventory on Consignment",
      key: "Percent Consigned",
      type: "percent"
    },
    {
      label: "Percent Inventory Purchased on Floor Plans (Auction or Otherwise)",
      key: "Percent Purchased On Floor",
      type: "percent"
    },
    {
      label: "Average # of Unit Sales Per Month",
      key: "Sales Per Month",
    },
    ],
  "Owner(s) Information": [
    {
      label: "Owner",
      type: "item",
      min: 1,
      max: 2,
      defaultValue: [{}],
      fields: [
        {
          label: "Name",
          //key: "name",
          required: true
        },
        {
          label: "Title",
          //key: "title"
        },
        {
          label: "Email",
          //key: "email",
          required: true
        },
        {
          label: "Address",
          //key: "address",
          type: "address",
          required: true
        },
        {
          label: "SSN",
          key: "Ssn",
          type: "ssn",
          required: true
        },
        {
          label: "DOB",
          key: "Dob",
          type: "date",
          required: true
        }
      ],
    },
    {
      label: "Other Authorized Signers",
      key: "Authorized Signer",
      description: "* I wish to authorize the following individual(s) to execute all funding documents, including contract assignments on behalf of the above dealership",
      type: "listitem",
      max: 5,
      defaultValue: [{}],
      fields: [
        {
          label: "Name",
          //key: "name"
        }
      ]
    },
    ],
    /*
    [Payment]
Transaction Method: Phone, Fax, Email
ACH or Debit
[Ach: Account label, bank label, bank acct #, routing #]
[Debit: Card label, Card #, Expiration, CVV, Billing Address]
    */
  "Payment Information": [
    {
      label: "Dealer Bank Account Authorized For Funding",
      key: "Ach",
      type: "ach"
    },
    {
      label: "Transaction Authorization Methods",
      type: "checkboxGroup",
      options: ["Phone", "Fax", "Email"],
      defaultValue: {
        "Phone": true,
        "Fax": false,
        "Email": false
      }
    },
    ],
  "Attestation": [
    {
      label: "I agree that this information is true, and I am authorized on behalf of the company to sign.",
      key: "Attestation",
      defaultValue: false,
      type: "checkbox",
      required: true
    }
  ]
}
