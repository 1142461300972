import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';


const LoadingDisplay = ({sx})=>{
    return(
      <Stack spacing={5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx}}>
        <CircularProgress />
        <Typography>Loading, please wait...</Typography>
      </Stack> 
    )
  }
export default LoadingDisplay;