import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import FieldsGenerator from 'components/forms/form-components/FieldsGenerator';


interface Props {
    item?: any,
}


export default function StepItemInput({register, control, item, errors}: Props) {  
  if (item !== undefined && item != null && item.length > 0) {
    return(
      <Card variant="outlined"><CardContent><FieldsGenerator meta={item} register={register} control={control} errors={errors} /></CardContent></Card>
    )
  }
  else {
    return null;
  }
}
