import * as React from 'react';

//react-hook-form
import { useFieldArray } from "react-hook-form";

import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import AddIcon from '@mui/icons-material/Add';

// icons
import CloseIcon from '@mui/icons-material/Close';
//input types
// elements
import RHFDynamicInput from './RHFDynamicInput';


type Props = {
  id?: string,
  items?: any,
  meta?: any,
  values?: any,
  formValues?: any,
  control?: any,
  itemMeta?: any,
  name?: any,
  register?: any,
  defaultVal?: any,
  fields?: any,
  append?: any,
  remove?: any,
  page?: any,
  errors?: any
};

/*
<Item
                key={field.id} // important to include key with field's id
                item={itemMeta}
                remove={remove}
                index={index}
              />

*/

function getMetaDefault(meta) {
  return meta.fields.reduce((result, field, i)=>{
    const key = field.key||field.label;
    result[key] = field.value||null;
    return result;
  }, {});
}

function Item({key_id, item, remove, index, name, register, control, errors}) {
  return(
    <Stack direction="row" spacing={2} sx={{alignItems: 'center', width: '100%'}}>
    {
        item.fields.map((subsubitem, k)=>{
          const item_subkey = `${name}.${index}.${subsubitem.key||subsubitem.label}`;
          return(<RHFDynamicInput 
              name={item_subkey}
              label={subsubitem.label}
              field_type={subsubitem.type}
              options={subsubitem.options}
              register={register}
              control={control}
              required={subsubitem.required}
              errors={errors}
            />
          );
        })
      }
      {((index+1)>(item.min||0))&&<CloseIcon fontSize='small' onClick={()=>remove(index)} sx={{cursor: "pointer"}} />}
    </Stack>
  );
}

export default function ListItemInput({register, control, name, itemMeta, errors}: Props) {
  
  const { fields, append, remove, } = useFieldArray({
    control,
    name: `${name}`
  });

  const defaultVal = getMetaDefault(itemMeta);
  /*
  
                {...register(key+subsubkey+index)}
                 */

  return(
    <>
          <Paper elevation={0} sx={{ minWidth: 275, pt: 2 }}>
        <Stack direction="row" sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        {itemMeta.description ? <Typography>{itemMeta.description}</Typography> : ""}
        </Stack>
        <Stack spacing={2} sx={{pt: 2, alignItems: 'center', width: '100%'}}>
        {
            fields.map((field, index) => {
              console.log("FIELD AND INDEX ", index, field)
              return(

              <Item
                key_id={field.id} // important to include key with field's id
                item={itemMeta}
                remove={remove}
                index={index}
                name={name}
                register={register}
                control={control}
                errors={errors}
              />
              
              );
            })
            }
            {(fields.length < itemMeta.max)&&<Stack direction="row" onClick={()=>append(defaultVal)} sx={{cursor: "pointer"}}>
              <AddIcon /> <Typography sx={{ml: 3}}>Add {itemMeta.label}</Typography>
            </Stack>
          }
        </Stack>
    </Paper>      
    </>
    );
}
