import * as React from 'react';
//react-hook-form
import { useFieldArray } from "react-hook-form";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

// icons
import CloseIcon from '@mui/icons-material/Close';
//input types
// elements
import RHFDynamicInput from './RHFDynamicInput';


type Props = {
  id?: string,
  items?: any,
  meta?: any,
  values?: any,
  formValues?: any,
  control?: any,
  itemMeta?: any,
  name?: any,
  register?: any,
  defaultVal?: any,
  fields?: any,
  append?: any,
  remove?: any,
  page?: any,
  errors?: any
};

/*
<Item
                key={field.id} // important to include key with field's id
                item={itemMeta}
                remove={remove}
                index={index}
              />

*/

function getMetaDefault(meta) {
  return meta.fields.reduce((result, field, i)=>{
    const key = field.key||field.label;
    result[key] = field.value||null;
    return result;
  }, {});
}


function Item({key_id, item, remove, index, name, register, control, errors}) {
  return(
    <Paper elevation={3} sx={{ minWidth: 275, p: 3 }}>
      <Stack direction="row" sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
      <Typography sx={{fontWeight: 'bold'}}>{item.label} {index+1}</Typography>
      {((index+1)>(item.min||0))&&<CloseIcon fontSize='small' onClick={()=>remove(index)} sx={{cursor: "pointer"}} />}
      </Stack>
      <Stack key={key_id}>
      {
        item.fields.map((subsubitem, k)=>{
          const item_subkey = `${name}.${index}.${subsubitem.key||subsubitem.label}`;
          return(<RHFDynamicInput 
              name={item_subkey}
              label={subsubitem.label}
              field_type={subsubitem.type}
              options={subsubitem.options}
              register={register}
              control={control}
              required={subsubitem.required}
              errors={errors}
            />
          );
        })
      }
      </Stack>
  </Paper>
  );
}


export default function ItemInput({register, control, name, itemMeta, errors}: Props) {
  
  const { fields, append, remove, } = useFieldArray({
    control,
    name: `${name}`
  });

  const defaultVal = getMetaDefault(itemMeta);
  /*
  
                {...register(key+subsubkey+index)}
                 */
  
  return(
    <>
          <Box sx={{width: '100%'}}>
          {itemMeta.description ? <Typography>{itemMeta.description}:</Typography> : ""}
          </Box> 
            {
            fields.map((field, index) => {
              console.log("FIELD AND INDEX ", index, field)
              return(

              <Item
                key_id={field.id} // important to include key with field's id
                item={itemMeta}
                remove={remove}
                index={index}
                name={name} 
                register={register} 
                control={control} 
                errors={errors}
              />
              
              );
            })
            }
            {(fields.length < itemMeta.max)&&<Paper elevation={3} sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3}}>
            <Typography sx={{ml: 3}}>Add {itemMeta.label}</Typography>
            <Fab color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
            <AddIcon onClick={()=>append(defaultVal)} />
            </Fab>
          </Paper> 
          }
    </>
    );
}
