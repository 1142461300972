import * as React from 'react';
import { styled } from '@mui/material/styles';


type Props = {
    strength: number,
    zIndex?: number,
    borderRadius?: number
}

const BlackFilter = styled('span')<Props>(({ theme, ...props }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  background: `rgba(0,0,0, ${props.strength})`,
  zIndex: props.zIndex || -1,
  borderRadius: props.borderRadius,
}));

export default BlackFilter;
