import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

//icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//elements
import StyledButtonFilled from 'components/elements/buttons/StyledButtonFilled';


type Props = {
  viewer: any,
  handlePage: any,
  tabs: any
}
type TabsMap = {
  [key: string]: {
    component: any
  }
}

type TabPanelProps = {
  children: any,
  value: any,
  index: any,
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: string) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props: any) => <Tab disableRipple indicatorColor={'none'} {...props} />)(
  ({ theme }) => ({
    textTransform: 'capitalize',
    letterSpacing: 2,
    marginRight: 0,
    minWidth: '50%', minHeight: '17vh', borderBottom: '0px solid white',
    color: 'rgba(255, 255, 255, 0.7)',
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderBottom: '0px solid white!important',
      width: '50%',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    }
  }),
);

/*
transition: `${theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,})}`,
    '&:hover': {
      transform: 'scale(1.1)',
    },
     */

const StyledLabel: any = styled(Typography)(({ theme }) => ({
    transition: `${theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,})}`,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }));

function TabLabel ({viewer, kv}) {
  return(
    <StyledLabel sx={{width: '100%', height: '100%', 
        fontSize: {xs: '1.0rem', sm: '2.0rem', xl: '3.0rem'}}}>
    <Stack direction="row" sx={{height: '100%', alignItems: 'center', justifyContent: 'center'}}>
        {kv}
        {viewer === kv ? <KeyboardArrowDownIcon sx={{ fontSize: {xs: '2.5rem', xl: '5.0rem'} }} />: 
                      <KeyboardArrowRightIcon sx={{ fontSize: {xs: '2.5rem', xl: '5.0rem'} }} />}
    </Stack>
    </StyledLabel>
  );
}


export default function HomeIntroMenu(props: Props) {
  const theme = useTheme();

  return (
    <Box id='homeintromenu'
    sx={{ width: '100%', alignItems: 'center', justifyContent: 'center', }}>
      <Tabs value={props.viewer} onChange={(e, v) => props.handlePage(v)} aria-label="basic tabs example"
        sx={{ bgcolor: theme.palette.primary.main, display: 'flex', justifyContent: 'space-between'}}>
          {
            Object.keys(props.tabs).map((key) => (
              <StyledTab 
              value={key} 
              onClick={() => props.handlePage(key)}
              label={<TabLabel viewer={props.viewer} kv={key} />} {...a11yProps(`${key}`)} />
            ))
          }
        </Tabs>
      {
        Object.entries(props.tabs as TabsMap).map(([key, item]) => (
          <TabPanel value={props.viewer} index={key}>
            {item.component}
          </TabPanel>
        ))
      }
    </Box>
  );
}
