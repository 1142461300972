import * as React from 'react';

// pages
import AltPage from "components/layouts/AltPage"
import JSXDisplaySimple from "components/elements/JSXDisplaySimple"

// styling
import "assets/scss/pages/home.scss";



type Props = {
  content: JSX.Element
}


const imgUrl = process.env.PUBLIC_URL + "/img/documents2.jpg";

export default function GeneralJSXDisplay(props: Props) {
  return (
    <AltPage useDefaultNavbar={false} img={imgUrl} content={<JSXDisplaySimple
      width={{xs: '90vw', md: '70vw', lg: '50vw'}}
      content={props.content} />} />
  );
}
