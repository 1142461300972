import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
   ThemeProvider,
   StyledEngineProvider,
   CssBaseline,
} from '@mui/material';

// layout
import Navbar from "components/navbars/Navbar"
import Footer from "components/footers/Footer"

//elements
import Background from "components/elements/Background"

// styling
import MainTheme from "components/themes/MainTheme"
import "assets/scss/pages/home.scss";


type Props = {
  content: JSX.Element,
  img: string,
  useDefaultNavbar: bool,
}

const navbarTheme = {
  bgColor: "transparent",
  color: "white",
  shadow: 0,
  logo: true,
};
//<Box sx={{height: '15vh'}} />

export default function AltPageDisplay(props: any) {
  const useDefaultNavbar = props.useDefaultNavbar;

  return (
    <>
    <Box sx={{minWidth: '100vw', maxWidth: '100vw'}} />
    <React.Fragment>
    <ThemeProvider theme={MainTheme}>
    <StyledEngineProvider injectFirst>
    <Navbar topTheme={navbarTheme} useDefault={useDefaultNavbar} />
    </StyledEngineProvider>
    <Stack sx={{display: 'inline-flex', alignItems: 'flex-start',
                justifyContent: 'flex-start', minWidth: '100%', maxWidth: '100%',}}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
      {useDefaultNavbar ? <Box sx={{height: '15vh'}} /> : ""}
      <Box sx={{width: '100%'}}>
      <Background img={props.img} height={useDefaultNavbar ? '90vh' : '100vh'} />
      {!useDefaultNavbar ? <Box sx={{height: '15vh'}} /> : ""}
        {props.content}
      </Box>
      <Footer />
      </StyledEngineProvider>
      </Stack>
    </ThemeProvider>
    </React.Fragment>
    </>
  );
}
