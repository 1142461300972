import * as React from 'react';
import {useReducer, useState, useEffect, useCallback, useLayoutEffect} from "react";
import axios from "axios";

//react-hook-form
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepButton from '@mui/material/StepButton';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// icons
//input types
// elements

// components
import FieldsGenerator from 'components/forms/form-components/FieldsGenerator'

import InfoDialog from 'components/elements/InfoDialog';
import { YupFormSchema, YupPageSchema } from 'components/forms/form-validation/DynamicYupSchema';


function getFormDefault(meta) {
  // Get form default values for react-hook-form
  return Object.entries(meta).reduce((result, [key, stepMeta], i)=>{
    const stepDefault = stepMeta.reduce((subResult, field, j)=> {
      const fkey = field.key||field.label;
      let defaultValue = field.defaultValue||null;
      if(typeof defaultValue === "number" && field.options) {
        defaultValue = field.options[field.defaultValue||0];
      }
      subResult[fkey] = defaultValue

      console.log("fkey", fkey)
      console.log("field", field)
      console.log("defaultValue", defaultValue)

      return subResult;
    }, {});
    result = {...result, ...stepDefault}
    console.log("DEFAULT FORM VALUES", result)
    return result;
  }, {});
}

function FormStep({step, register, control, errors, errorMessage, isValid, lastStep, handleBack, handleNext, formMap, formPages}) {
  const meta = formMap[formPages[step]];
  const isLastStep = step===lastStep;

  return(
    <Paper elevation={3} sx={{p: 5}}>
    <Stack spacing={2}>
    <FieldsGenerator page={step} meta={meta}
                    register={register} control={control} errors={errors} />
    {(!isValid) && isLastStep && errorMessage !== "" ? <Typography sx={{color: 'error.contrastText', bgcolor: 'error.main', border: '1px solid red', borderRadius: 20, p: 2}}>{errorMessage}</Typography> : ""}
    
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
    {step>0 ?<Button key="backbutton" type="button" variant="contained" sx={{ml: 1, height: {xs: 50, sm: '97%'}}}
            onClick={()=>handleBack()}>Back</Button>: <Box></Box>}
    
    { isLastStep ? <Button key="submitbutton" type="submit" variant="contained" sx={{ml: 1, height: {xs: 50, sm: '97%'}}}
            disabled={!isValid}>Submit</Button> : <Button key="nextbutton" type="button" variant="contained" sx={{ml: 1, height: {xs: 50, sm: '97%'}}}
            onClick={()=>handleNext()}>Next</Button>}
    </Box>
    </Stack>
    </Paper>
  )
}


export default function CustomStepperForm({formPages, formMap, formatValues, submitUrl, orientation, minWidth, formWidth, responses}) {
  const steps = formPages;
  const lastStep = steps.length-1;
  const [responseKey, setResponseKey] = useState("loading");
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const response = (responses||{})[responseKey];

  const [step, setStep] = useState(0);
  const [prevStep, setPrevStep] = useState(step);
  const [submitted, setSubmitted] = useState(false);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  function pageErrorsReducer(state, action) {
    switch (action.type) {
      case 'update':
        return {...state, [action.step]: action.values};
      default:
        throw new Error();
    }
  }
  const [pageErrors, dispatchPageErrors] = useReducer(pageErrorsReducer, {});
  const [errorMessage, setErrorMessage] = useState("");

  // react-hook-form
  const validationSchema =  YupFormSchema(formMap);
  //const validationSchema =  {};
  const pageSchemas = formPages.reduce((prev, curr, i) => {
    prev[i] = yup.object(YupPageSchema(formMap[formPages[i]]));
    return prev;
  }, {})
  
  const methods = useForm(
    {
      resolver: yupResolver(validationSchema), 
      mode: "onChange",
      reValidateMode: 'onChange',
    });
    
  const {register, control, getValues, setValue, reset, handleSubmit, setError, formState: { errors, isValid }} = methods;
  //const {register, control, getValues, setValue, reset, handleSubmit, formState: { errors }} = RHForm;
  

// window resize

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
const [windowWidth, windowHeight] = useWindowSize();
const [formOrientation, setFormOrientation] = useState(orientation);

useEffect(()=>{
  setFormOrientation(windowWidth < 1100 ? "vertical" : orientation)
}, [windowWidth, orientation])


// form values
  function submitForm(data: any) {
    console.log("TRIGGERED SUBMIT");
    console.log("DATA TB SUBMITTED", data);
    const formatted_data = formatValues(data);
    console.log("SUBMIT DATA FORMAATTED", formatted_data);
    sendFormData(formatted_data);
    
    //event.preventDefault();
    setShowResponseDialog(true);
    
  };

  async function sendFormData(input: any) {
    console.log("Sending form data...")
    try {
     const sendInput = input;
      console.log("VAL SENDING, ", sendInput);
      const { data } = await axios.post(`${submitUrl}`, sendInput);
      setResponseKey(data["is_success"] !== false ? "success": "error");
      //setResponse(data);
    } catch (error) {
      setResponseKey("error");
    }
  }

  const resetFormToDefault = useCallback(()=> {
    const defValue = getFormDefault(formMap);
    //console.log("DEFVALUE", defValue);
    reset(defValue);
  }, [formMap, reset]);

  useEffect(()=>{
    resetFormToDefault();
  }, [resetFormToDefault]);
  
  function handleNext() {
    //handleValidation(step);
    setPrevStep(step);
    handleValidPage();
    setStep((ps) => ps+1);
  }
  function handleBack() {
    //handleValidation(step);
    setPrevStep(step);
    handleValidPage();
    setStep((ps) => ps-1);
  }
  function handleI(i) {
    //handleValidation(step);
    setPrevStep(step);
    handleValidPage();
    setStep(()=>i);
  }


  async function validateWithYup () {
    console.log("RUNNING CALLBACK")
    const i = step;
    //const pageSchema = yup.object(YupPageSchema(formMap[formPages[i]]));
    const pageSchema = pageSchemas[i];
    const data = getValues();
  
    let res = {errors:{}, values:{}};
    try {
      const val = await pageSchema.validate(data, {
        abortEarly: false
      });
  
      res = {
        ...res,
        values: val
      };
    } catch (errors) {
      console.log("FOUND ERRS: ", errors);
      const errsInner = errors.inner||[];
      res = {
        values: {},
        errors: errsInner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? "validation",
              message: currentError.message
            }
          }),
          {}
        )
      };
      errsInner.forEach(({ name, path, type, message }) =>
        setError(path, { type, message })
      );  
    }
    console.log("Collected errors: ", res);
    dispatchPageErrors({type: 'update', step: i, values: res.errors});
    return res;
  }


const handleValidPage = () => {
  validateWithYup();
  console.log("PAGE ERRORS", pageErrors);
}

useEffect(()=>{
  const errs = pageErrors;
  const i = prevStep;
  console.log("CHECKING STEP ", i, " EVALED TO ", Object.keys(errs[i]||{}).length === 0)
  console.log("STEP ERROR", errs[i]);
  if (i in errs && Object.keys(errs[i]||{}).length !== 0) {
    console.log("STEP FAILED");
    setCompleted((c)=>({...c, [i]: false}));
  }
  else if (i in errs) {
    console.log("STEP COMPLETE")
    setCompleted((c)=>({...c, [i]: true}));
  }
}, [pageErrors, prevStep]);



// set error message
useEffect(()=>{
  let errmsg = "";
  let errorPages = Object.entries(completed).reduce((prev, [k, v], i)=>{if(v === false) prev.push(parseInt(k)+1); return prev;}, []).join(", ");
  if(Object.keys(errors).length > 0) {
    errmsg = "Please review all steps and fix the form errors on pages: "+ errorPages;
  }
  else if((Object.keys(completed).length < lastStep) && (Object.keys(errors).length < 1)) {
    errmsg = "Please review all steps before submitting.";
  }
  setErrorMessage(errmsg);
}, [completed, errors, lastStep]);
  

function onSubmitClose() {
  if(responseKey === 'success') {resetFormToDefault(); setSubmitted(true);}
}
  

  /*
  <ResponseDialog responses={responses} 
        showResponseDialog={showResponseDialog} 
        setShowResponseDialog={setShowResponseDialog} 
        responseKey={responseKey} 
        setResponseKey={setResponseKey} 
        onClose={onSubmitClose}
        />
  */

  return(
    <Container id="contactus" sx={{pt: 5,
      display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center',
      minWidth: '100%', maxWidth: '100%', minHeight: '70vh'}}>
  
  <InfoDialog show={showResponseDialog} setShow={setShowResponseDialog} maxWidth="sm"
              onClose={onSubmitClose}
              title={<>{response.icon}&nbsp;{response.title}</>} body={<>{response.body}</>} />

    {!submitted ? 
    <Box sx={{ width: '100%', alignItems: 'center' }}>
      <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitForm)}>
        {console.log("PREV STEP:", prevStep)}
        {console.log("VALUES:", getValues())}
      {console.log("MAIN FORM ERRORS:", errors)}
      {console.log("PAGE ERRORS: ", pageErrors)}
      <Stack sx={{ width: '100%', alignItems: 'center' }} spacing={4}>
        <Stepper nonLinear activeStep={step} orientation={formOrientation}
        sx={{width: "100%"}}> 
        {
          steps.map((label, i) => {
            return (
              <Step key={label} completed={completed[i]}>
              <StepButton color="inherit" type="button" onClick={()=>handleI(i)}>
                <StepLabel error={i in completed && !completed[i]}>
                  <Typography sx={{fontSize: "1em", fontWeight: step===i ? 'bold': 'none'}}>{label}</Typography>
                  </StepLabel>
              </StepButton>
              {
              formOrientation==="vertical"&&<StepContent>
                <FormStep step={step}
                register={register} 
                control={control} 
                errors={errors} 
                errorMessage={errorMessage} 
                isValid={isValid} 
                lastStep={lastStep} 
                handleBack={handleBack}
                handleNext={handleNext} 
                formMap={formMap}
                formPages={formPages}
                />
              </StepContent>
            }
            </Step>
            );
          }
          )
        }
        </Stepper>
        <Box sx={{width: formWidth}}>
        {
          formOrientation==="horizontal"&&<FormStep step={step} 
                register={register} 
                control={control} 
                errors={errors} 
                errorMessage={errorMessage} 
                isValid={isValid} 
                lastStep={lastStep} 
                handleBack={handleBack}
                handleNext={handleNext} 
                formMap={formMap}
                formPages={formPages}
          />
        }
        </Box>
        </Stack>
      </form>
  </FormProvider>
    </Box>
    :
    <>{responses["success"]?.body}</>
  }
  </Container>
  );
}

