import * as React from 'react';
import { useFormContext, Controller } from "react-hook-form";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FieldLabel from './FieldLabel';


//onChange={useCallback(debounce(onChange, 300), [])}
/*
 <>
        Props: {JSON.stringify(props)}
        InputProps: {JSON.stringify(props.InputProps)}
        <Controller
        render={
          ({ field }) => <TextField {...props} {...register(`${props.name}`)} />
        }
        control={control}
        name={`${props.name}`}
        defaultValue={10}
      />
      </>
       */
export default function RHFCheckboxInput ({name, required, ...props}) {
    const { register, control } = useFormContext();
    return (
      <FormControlLabel control={
      <Controller
          name={`${name}`}
          control={control}
          render={({ field: { value, ...field } }) => (
            <Checkbox
            {...field}
            checked={!!value}
            />
          )}
          rules={{}}
        />
          } label={<FieldLabel label={props.label} required={props.required} />} />
    );
}
