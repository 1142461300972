import * as React from 'react';
import * as lodash from 'lodash';
import { useFormContext } from "react-hook-form";

import TextField from '@mui/material/TextField';

import FieldLabel from './FieldLabel';

//onChange={useCallback(debounce(onChange, 300), [])}
/*
 <>
        Props: {JSON.stringify(props)}
        InputProps: {JSON.stringify(props.InputProps)}
        <Controller
        render={
          ({ field }) => <TextField {...props} {...register(`${props.name}`)} />
        }
        control={control}
        name={`${props.name}`}
        defaultValue={10}
      />
      </>
       */
export default function RHFTextInput ({name, label, errors, ...props}) {
    const { register, control, getFieldState, trigger } = useFormContext();
    const err = lodash.get(errors||{}, name);
    
    return (
      <><FieldLabel label={label} required={props.required} />
      <TextField 
        id={name}
        name={name}
        required={props.required} 
        error={Boolean(err)} 
        helperText={err && err.message}
        inputProps={register(`${name}`, {...props.inputProps})} 
        {...props} 
        fullWidth />
      </>
    );
}
