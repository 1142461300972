import * as React from 'react';
import {useState, useEffect, useLayoutEffect} from "react";

import { useParams } from "react-router-dom";
import throttle from "lodash.throttle";
import { useMediaQuery } from 'react-responsive';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
   ThemeProvider,
   StyledEngineProvider,
   CssBaseline,
} from '@mui/material';

// elements
import MainTheme from "components/themes/MainTheme"
import Navbar from "components/navbars/Navbar"
import Header from "components/headers/Header"
import Footer from "components/footers/Footer"

// pages
import HomeIntroMenu from "views/index-sections/HomeIntroMenu"
import FAQ from "views/index-sections/FAQ"
import ContactUsForm from "views/index-sections/ContactUsForm"
import Payments from "views/index-sections/Payments"
import Dealers from "views/index-sections/Dealers"

// styling
import "assets/scss/pages/home.scss";


type ParamProps = {
  page?: string
}

// window positioning
const windowOffsetScale = 0.15;
const offset = document.documentElement.clientHeight * windowOffsetScale;
const pageOffset = 700;

// themes
const navbarTheme = {
  bgColor: "transparent",
  color: "white",
  shadow: 0,
  logo: true,
};
let headerTheme = {
  img: getPublicImgUrl("cristofer.jpg"),
  height: '83vh',
};
const dealersTheme = {
  img: getPublicImgUrl("dealership_flip.png"),
}
const paymentTheme = {
  img: getPublicImgUrl("checkbook.jpg"),
}
const faqTheme = {
  img: getPublicImgUrl("questiondog.jpg"),
};

const refs:any = {
  "header": React.createRef(),
  "customers": React.createRef(),
  "dealers": React.createRef(),
  "faq": React.createRef(),
  "contactus": React.createRef()
};


function getPublicImgUrl(url: string) {
  return process.env.PUBLIC_URL + "/img/"+ url;
}


const calcComponentBounds = (refs: any) => {
  const refBounds:any = {};
  let minOffset = 0;
  Object.keys(refs).forEach((k, i)=> {
      const refBound = refs[k]?.current?.getBoundingClientRect();
      if(refBound?.top) refBounds[k] = [refBound?.top, refBound?.bottom];
      if(refBound?.top < minOffset) minOffset = refBound?.top;
  })
  minOffset += pageOffset;
  Object.keys(refBounds).forEach((key) => {refBounds[key][0] -= minOffset; refBounds[key][1] -= minOffset})
  return refBounds;
};


const executeScroll = (ref: any, offset: number) => window.scrollTo({ top: ref.current.offsetTop-offset, behavior: 'smooth', block: 'start' } as any);
const handleNav = (val: string) => {
  let ref = refs[val];
  if (val in refs && ref.current != null) {
    executeScroll(ref, offset);
  }
};


const getPageByPosition = (windowPage, bounds) => {
  //const offset = document.documentElement.clientHeight * windowOffsetScale;
  const windowY = document.documentElement.scrollTop + offset;
  for (const [key, value] of Object.entries(bounds)) {
    if (value?.length > 1 && windowY > value[0] && windowY < value[1]) {
      windowPage = key;
      break;
    }
  }
  return windowPage;
}


export default function Home() {
  let params = useParams<ParamProps>();
  let initialPage = params.hasOwnProperty('page') ? params.page : "header";

  const isMobile = useMediaQuery({ maxWidth: 767 });
  headerTheme.height = isMobile ? '77vh' : headerTheme.height;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const [scrollViewPage, setScrollViewPage] = useState(initialPage);
  const [urlPage, setUrlPage] = useState(initialPage);
  const [customerType, setCustomerType] = useState(initialPage === "dealers" ? "dealers" : "customers");
  const [bounds, setBounds] = useState({});

  const introTabs = {
    "customers": {
      component: <Payments pageRef={refs["customers"]} theme={paymentTheme} animate={currentPage==='customers'} />
    },
    "dealers": {
      component: <Dealers pageRef={refs["dealers"]} theme={dealersTheme} animate={currentPage==='dealers'} />
    }
  }

  function changePageByUrl(p: any) {
    if(p in introTabs) {
      setCustomerType(p);
      handleNav(p);
    }
    setUrlPage(p);
    setCurrentPage(p);
  }

  const changePageByScroll = () => {
    let windowPage = getPageByPosition(scrollViewPage, bounds);
    console.log("Window is: ", windowPage)
    if(windowPage in introTabs) {
      windowPage = customerType;
    }
    if(windowPage !== scrollViewPage) {setScrollViewPage(windowPage)}
  };



useEffect(()=>{
  setCurrentPage((cp)=>cp !== scrollViewPage ? scrollViewPage : cp)
}, [scrollViewPage, setCurrentPage]);

useLayoutEffect(()=>{
  const refBounds = calcComponentBounds(refs);
  setBounds(refBounds);
}, [setBounds]);

useLayoutEffect(()=>{
  handleNav(urlPage||"");
}, [urlPage]);

const throttledPageScroller = throttle(changePageByScroll,200);
useLayoutEffect(()=>{
  if (Object.keys(bounds).length > 0) {
    window.addEventListener("scroll", throttledPageScroller);
    return function cleanup() {
      window.removeEventListener("scroll", throttledPageScroller);
    };
  }
}, [scrollViewPage, customerType, bounds, throttledPageScroller]);


  return (
    <>
    <Box sx={{minWidth: '100vw', maxWidth: '100vw'}} />
    <React.Fragment>
    <ThemeProvider theme={MainTheme}>
    <StyledEngineProvider injectFirst>
    <Navbar topTheme={navbarTheme} handlePage={changePageByUrl} currentPage={currentPage} />
    <Header pageRef={refs["header"]} theme={headerTheme} />
    </StyledEngineProvider>
    <Stack sx={{display: 'inline-flex', alignItems: 'flex-start',
                justifyContent: 'flex-start', minWidth: '100%', maxWidth: '100%',}}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
      <HomeIntroMenu
        viewer={customerType}
        handlePage={changePageByUrl}
        tabs={introTabs}
        />
      <FAQ pageRef={refs["faq"]} theme={faqTheme} currentPage={currentPage} animate={scrollViewPage==='faq'} />
      <ContactUsForm pageRef={refs["contactus"]} />
      <Footer handlePage={changePageByUrl} />
      </StyledEngineProvider>
      </Stack>
    </ThemeProvider>
    </React.Fragment>
    </>
  );
}
