import * as React from 'react';
import { createTheme } from '@mui/material/styles';


const MainTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1400,
      xl: 2500,
    },
  },
  palette: {
    primary: {
      // dark blue
      main: "#003153",
      contrastText: '#FFF',
      light: "#1A4665"
    },
    secondary: {
      // yellow
      main: '#d4af37',
      contrastText: '#000'
    },
    info: {
      main: "#EFEFEF",
      contrastText: "#707070"
    },
    neutral: {
      main: "#DDD",
      contrastText: "#000"
    },
    white: {
      main: "#FFF",
      contrastText: "#000"
    },
    black: {
      main: "#000",
      contrastText: "#FFF"
    },
  },
  typography: {
    fontFamily: 'Basis Grotesque Light',
    h4: {
      fontFamily: 'Basis Grotesque Regular',
      fontSize: '2.0rem',
      letterSpacing: -1
    },
    h5: {
      fontFamily: 'Basis Grotesque Regular',
      fontSize: '1.4rem',
      letterSpacing: -1
    },
    h6: {
      fontFamily: 'Basis Grotesque Regular',
      fontSize: '1.2rem',
      letterSpacing: -1
    },
    body1: {
      fontSize: '1.0rem'
    },
    body2: {
      fontSize: '0.8rem'
    },
  },
});
export default MainTheme;
