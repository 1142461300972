import React from "react";
import {useReducer, useState, useEffect} from "react";
import axios from "axios";
import qs from 'qs';

import { useMediaQuery } from "react-responsive";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// icons
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// elements
import PhoneNumberInput from 'components/forms/form-input-types/debounce/PhoneNumberInput';
import LoadingDisplay from 'components/elements/LoadingDisplay';


// styling



type Props = {
  pageRef: any,
}
const responses = {
  "success": {
    icon: <CheckIcon />,
    title: "Message Sent",
    body: "Your message has been received. One of our representatives will contact you shortly."
  },
  "error": {
    icon: <ErrorOutlineIcon />,
    title: "Error",
    body: "Something went wrong while sending message. Please try again."
  },
  "loading": {
    body: <LoadingDisplay />
  }
}
const preferredContactMethods = ["either", "email", "phone"];
const contactUsInitialState = {
  phone: '(100) 000-0000',
  preferred: preferredContactMethods[0]
};

const url = process.env.REACT_APP_API_URL+"/contact?template=contactus";
const rightFieldSize = 200;
const rightFieldSizeTxt = {xs: "100%", md: "200px"};
const leftFieldSize = {xs: '100%', md: `calc(100% - ${rightFieldSize+20}px)`};


function loadGoogleRecaptcha() {
  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js";
  script.async = true;
  document.body.appendChild(script);
};

function clearAllInput() {
  let inputElements = document.getElementsByTagName("input");
  let textareaElements = document.getElementsByTagName("textarea");
  Array.from(inputElements).forEach((item,i)=>{if (item.type === "text") item.value = "";});
  Array.from(textareaElements).forEach((item)=>{item.value=""});
  if (window.grecaptcha) grecaptcha.reset();
}


export default function ContactUsForm(props: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [responseKey, setResponseKey] = useState("loading");
  const [showResponseDialog, setShowResponseDialog] = useState(false);
  const [contactUsState, dispatchContactUs] = useReducer(contactUsReducer, contactUsInitialState);
  function contactUsReducer(state: any, action: {type: string, value: string}) {
    switch (action.type) {
      case 'phone':
        return {...state, phone: action.value};
      case 'preferred':
        return {...state, preferred: action.value};
      default:
        throw new Error();
    }
  }

  function Preferred({fieldSize}) {
    return(
      <FormControl sx={{ mt: 1, width: fieldSize }} size="small">
        <InputLabel id="contactus-preferred-contact-label">Preferred Contact</InputLabel>
        <Select
          labelId="contactus-preferred-contact-label"
          id="contactus-preferred-contact"
          name="contactus-preferred-contact"
          label="Preferred Contact"
          value={contactUsState.preferred}
          onChange={(e: any)=>{dispatchContactUs({type: 'preferred', value: e.target.value})}}
        >
          { preferredContactMethods.map((item)=>{
            return(
              <MenuItem value={item} key={item}><Typography sx={{textTransform: 'capitalize'}}>{item}</Typography></MenuItem>
            );
          }) }
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    loadGoogleRecaptcha();
  }, []);

  function handleFormSubmit(event: any) {
    event.preventDefault();
    setShowResponseDialog(true);
    const target = event.target;
    const input = {
      "name": target["contactus-name"].value,
      "phone": target["contactus-phone"].value,
      "email": target["contactus-email"].value,
      "preferred_contact": target["contactus-preferred-contact"]?.value||preferredContactMethods[0],
      "msg": target["contactus-message"].value,
      "template": "contactus",
      "captcha": target["g-recaptcha-response"]?.value||""
    }
    sendFormData(input);
  };

  async function sendFormData(input: any) {
    try {
      const { data } = await axios.post(`${url}`, qs.stringify(input));
      setResponseKey(data["is_success"] === true ? "success": "error");
    } catch (error) {
      setResponseKey("error");
    }
  }

  function handleDialogClose() {
    setShowResponseDialog(false);
    if (responseKey === "success") {
      clearAllInput();
    }
    setResponseKey("loading");
  }

  function ResponseDialog() {
    return(
      <Dialog open={showResponseDialog} onClose={handleDialogClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>{responses[responseKey]?.icon}&nbsp;&nbsp;{responses[responseKey]?.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {responses[responseKey]?.body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>Close</Button>
          </DialogActions>
      </Dialog>
    );
  }

  return (
    <Container ref={props.pageRef} id="contactus" sx={{padding: 10,
    display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center',
    minWidth: '100%', maxWidth: '100%', minHeight: '55vh',
    backgroundColor: theme.palette.info.main
    }}>

    <ResponseDialog />

      <Box sx={{width: {xs: '90vw', md: '60vw', lg: '45vw', xl: '25vw'},
      display: 'inline-flex', justifyContent: 'space-between', flexWrap: 'wrap',
       p: 5, boxShadow: 1, borderRadius: 5, maxWidth: 600, backgroundColor: "#FFF"
       }}>
        <Typography variant="h4">Contact Us</Typography>
        <Stack sx={{display: 'flex', alignItems: 'flex-end'}}>
          <Typography><PhoneIcon fontSize={'10em'} sx={{mr: 1}} />
            <a href='tel: 949-284-8818'>949-284-8818</a> or toll free: <a href='tel: 877-254-9409'>877-254-9409</a></Typography>
          <Typography><LocationOnIcon  fontSize={'10em'} sx={{mr: 1}}  />PO Box 10847 Newport Beach, CA 92658</Typography>
        </Stack>


        <form onSubmit={handleFormSubmit}>
        <Box sx={{width: {xs: '65vw', sm: '100%'}, mt: 2,
        display: 'inline-flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>

        <TextField required id="contactus-name" name="contactus-name" label="Name" variant="standard"
        sx={{mb: 1, width: leftFieldSize}} />

          <FormControl sx={{mt: {xs: 1, sm: 0}, mb: 1, width: rightFieldSizeTxt}}>
            <PhoneNumberInput
              required
              label="Phone #"
              variant="standard"
              value={contactUsState.phone}
              onChange={(e: any)=>{dispatchContactUs({type: 'phone', value: e.target.value})}}
              name="contactus-phone"
              id="contactus-phone" />
          </FormControl>

          <TextField required id="contactus-email" label="Email" variant="standard"
            sx={{mb: 1, width: leftFieldSize}} />
          <Preferred fieldSize={rightFieldSizeTxt} />

          <Box sx={{mt: 2, mb: 1, width: '100%'}}>
          <TextareaAutosize
            required
            id="contactus-message"
            aria-label="contactus-message"
            minRows={5}
            placeholder="Message"
            style={{ width: '100%' }}
          /></Box>
          <Box sx={{display: "inline-flex", justifyContent: "flex-end", alignItems: 'center', flexWrap: 'wrap', width: '100%'}}>
            <Box sx={{mb: {xs: 1, sm: 0}}}>
              <div 
              class="g-recaptcha" data-size={isMobile? "compact" : "normal"} 
              data-sitekey="6LfiYoolAAAAAFXKwE1_oxDfTXuD4vZm5gv7QVC8" 
              />
              </Box>
            <Button type="submit" variant="contained" sx={{ml: 1, height: {xs: 50, sm: '97%'}}}>Submit</Button>
          </Box>
          </Box>
          </form>

      </Box>
    </Container>
  );
}
