import * as yup from "yup";
import {addressSchema} from 'components/forms/form-input-types/reacthookform/RHFAddressInput';
import {achSchema} from 'components/forms/form-input-types/reacthookform/RHFACHPaymentInput';

// other types

const yupTypes = {
  "text": "string",
  "date": "date",
  "currency": "number",
  "number": "number",
  "address": addressSchema,
  "tel": "mask",
  "checkboxGroup": "object",
  "checkbox": "bool",
  "ach": achSchema
}

/*
[
    //validation for step1
    yup.object({
      'Legal Company Name': yup.string().required(),
      DBA: yup.string().required()
    }),
    //validation for step2
    yup.object({
      address: yup.string().required()
    }),
    //validation for step3
    yup.object({
      job: yup.string().required()
    })
  ];
*/

function addRule(elem, elemSchema) {
  //console.log("ELEM", elem);
  //let elemType = "";
  const newType = yupTypes[elemSchema.type];
  //console.log("ELEM", elem, "elemSchema", elemSchema, "eSType", elemSchema.type);
  elem = elem.nullable();
  if (elemSchema.required === true) {
    elem = elem.required("This field is required")
    if (newType === 'bool') {
      //console.log("adding checkbox bool");
      elem = elem.oneOf([true], 'Field must be checked');
    }
    else if (newType === 'mask') {
      const x = 0;
      //elem = elem.transform((value) => (value||"").replace(/[^\d]/g, ''))
      //elem = elem.test('Not valid','Not valid',(value) => !("_" in value))
      //elem = elem.matches(/.*\d/, 'Should contain a digit')
    }
  }
  //console.log("final elem", elem);
  return elem;
}

function baseType(fieldtype) {
  let elemType = yupTypes[fieldtype]||"string";
  let elemNewType = elemType === 'mask' ? "mixed" : elemType;
  let elem = yup[elemNewType]();
  if(elemType === 'date') {
    elem = elem.transform((curr, orig) => orig === '' ? null : curr);
  }
  return elem;
}

function customType(x) {
  let yupType = yupTypes[x.type]||"string";
  yupType = yupType === 'mask' ? "mixed" : yupType;
  if (typeof(yupType) !== "string") {
    //console.log("not yup type: ", x.type)
    const schema = Object.entries(yupType).reduce((prev, [k,v], i)=>{
      let elem = baseType(v.type);
      prev[k] = addRule(elem, x);
      return prev;
    }, {})
    //console.log("non string schema for ", x.type, schema);
    return yup.object().shape(schema);
  }
  else {
    let elem = baseType(x.type);
    return addRule(elem, x);
  }
}

function customFields(elem) {
  if (elem.fields) {
    //console.log("FIELDS ", elem.fields);
    const fields = elem.fields;
    let x = fields.reduce((prev, curr, i)=>{
      const key = curr.key||curr.label;
      prev[key] = customType(curr);
      return prev;
    },{})
    return yup.array().of(yup.object().shape(x));
  }
  else {
    return customType(elem);
  }
}

function getStepSchema(elem) {
  let parentKey = elem.key||elem.label;
  /*
  let x = Object.entries(elem.step).reduce((prev, [k, v], i)=>{
    let y = v.forEach((el, i)=> {
      let subkey = el.key||el.label;
      let ele = baseType(el.type);
      ele = ele.when(parentKey, {
        is: k,
        then: (s)=>s.required(),
        otherwise: (s) => s 
      });
      prev[subkey] = ele;
    })
    return prev;
  },{})
  return x;
  */
}


export function YupFormSchema(pages) {
  const x = Object.entries(pages).reduce((prev, [k, v], i) => {
      const pageSchema = YupPageSchema(v);
      prev = {...prev, ...pageSchema};
      return prev;
  }, {})
  //console.log("YUP SCHEMA: ", x);
  const yupSchema = yup.object(x);
  return yupSchema;
}

export function YupPageSchema(pageSchema) {
  const schema = pageSchema.reduce((prev, curr, i)=>{
    const key = curr.key||curr.label;
    //const rules = {required: curr.required};
    let elem = customFields(curr);
    prev[key] = elem;
    if (curr.step && false) {
      prev = {...prev, ...getStepSchema(curr)}
    }
    return prev;
  }, {})
  console.log("getting called YUP PAGE SCHEMA: ", schema);
  return schema;
}
