import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


export default function InfoDialog ({show, setShow, onClose, title, body, maxWidth}) {
  const closeDialog = ()=>{ setShow(false); onClose && onClose(); };
  return(
    <Dialog open={show} onClose={closeDialog} fullWidth maxWidth={maxWidth||"xs"}>
      <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>{title}</DialogTitle>
      <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>Close</Button>
        </DialogActions>
    </Dialog>
  )
}
