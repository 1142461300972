import * as React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
    strength: any,
}

const GradientBlueFilter = styled('span')<Props>(({ theme, ...props }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: '100%',
  background: `linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(3, 161, 224, ${props.strength}))`,
  zIndex: -1,
}));

export default GradientBlueFilter;
