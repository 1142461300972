import * as React from 'react';
import {useState} from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import Typography from '@mui/material/Typography';


type TreeProps = {
  collapseIcon: any,
  expandIcon: any,
  endIcon: any,
  map: any,
  ariaLabel: string,
  titleClassName?: string,
  bodyClassName?: string
  sx?: any,
}

interface TreeItem {
    title: string,
    body: any
}


function TransitionComponent(props: any) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
    in: PropTypes.bool,
};

const StyledTreeCategory = styled((props: any) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  textAlign: 'justify',
  pb: 5, pt: 5,
  display: 'table',
  width: 'auto',
  color: 'inherit' /*props.nodeId in props.selected||[] ? activeColor: 'inherit',*/,
  'a:link, a:active, a:hover, a:visited': {
    color: 'inherit'
  }
  ,selected: {
  '&.Mui-selected': {
      backgroundColor: "turquoise",
      color: "white",
      fontWeight: 600,
  }},
  [`& .${treeItemClasses.label}`]: {
    borderRadius: theme.shape.borderRadius,
    marginTop: 3,
    marginBottom: 3,
    selected: {
      color: '#FFF'
    },
    '&:': {
      display: 'inline-block',
      position: 'relative'
    },
    '&:hover': {
      color: '#FFF',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: '2px',
      bottom: 0,
      left: 0,
      backgroundColor: '#FFF',
      visibility: "hidden",
      transition: 'all 0.3s ease-in-out',
    },
    '&:hover::before': {
      visibility: 'visible',
      width: '100%',
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `0px solid #fff`,
  },
}));

const StyledTreeItem = styled((props: any) => (
  <StyledTreeCategory {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`&:`]: {
    p: 5
  },
}));



export default function QATree(props: any) {
  const [activeItems, setActiveItems] = useState({});

  function toggleActiveItem(item: string) {
    if (activeItems != null && activeItems.hasOwnProperty(item)) {
      //delete activeItem[item]
    }
    else {
      setActiveItems({...activeItems, [item]: null});
    }
  }

const StyledLabel = styled(Typography)(({ label, activeColor }: any) => ({
  color: activeItems != null && label in activeItems ? activeColor: 'inherit'
  /*
  cursor: 'pointer',
  transition: `${theme.transitions.create(['color', 'transform'], {
    duration: theme.transitions.duration.standard,})}`,
    */
}));


  return (
    <TreeView
      aria-label={props.ariaLabel}
      defaultExpanded={['1']}
      defaultCollapseIcon={props.collapseIcon}
      defaultExpandIcon={props.expandIcon}
      defaultEndIcon={props.endIcon}
      {...props}
      >
      {
        Object.entries(props.map).map(([k, v]: any, i: number)=> (
          <StyledTreeCategory nodeId={`${props.ariaLabel}-${i}`}
          selected={[]}
          onNodeToggle={(e: any)=>toggleActiveItem(k)}
          label={<Typography variant="h5" sx={{
                color: activeItems != null && k in activeItems ? props.activeColor: 'inherit'
                }}
                onClick={()=>toggleActiveItem(k)}
                className={props.categoryClassName}>{k}</Typography>}>
          {
            v.map((item: any, j: number)=> (
              <StyledTreeItem nodeId={`${props.ariaLabel}-${i}-${j}`}
              label={<StyledLabel variant="body1" onClick={()=>toggleActiveItem(item.title)}
                                  className={props.titleClassName}
                                  label={item.title}
                                  activeColor={props.activeColor}
                                  >{item.title}</StyledLabel>}>
                <Typography variant="body1" className={props.bodyClassName} sx={{color: props.activeColor}}>{item.body}</Typography>
              </StyledTreeItem>
          ))}
          </StyledTreeCategory>
        ))
      }
    </TreeView>
  );
}
