import * as React from 'react';
import { useParams } from "react-router-dom";

// pages
import AltPage from "components/layouts/AltPage"
import ContentDisplaySimple from "components/elements/ContentDisplaySimple"

// styling
import "assets/scss/pages/home.scss";



type Response = {
  title: string,
  body: string | string[] | JSX.Element,
  listType?: string,
  listTitle?: string
}
type ResponseMap = {
  [key: string]: Response
}
type Props = {
  map: ResponseMap
}


const imgUrl = process.env.PUBLIC_URL + "/img/documents2.jpg";
  

export default function GeneralDataDisplay(props: Props) {
  const map: ResponseMap = props.map;
  const {key} = useParams();
  const keyString = key?.toLowerCase() as string;
  const contentItem = map[keyString]||{title: "", body: "", listType: ""};
  const title = contentItem.title;
  const body =contentItem.body;
  const listType = contentItem.listType;
  const listTitle = contentItem.listTitle;

  function ListBody(items: string[], listType: string, listTitle: string) {
    return(
      <>
      {listTitle}
      {
        listType === 'ordered' ? 
        <ol>
          {items.map((item)=>{
            return(
              <li>{item}</li>
            );
          })}
        </ol>
        :
        <ul>
          {items.map((item)=>{
            return(
              <li>{item}</li>
            );
          })}
        </ul>
      } 
      </>
    )
  }

  return (
    <AltPage useDefaultNavbar={false} img={imgUrl} content={<ContentDisplaySimple
      img={imgUrl}
      width={{xs: '90vw', md: '50vw', lg: '50vw'}}
      title={title}
      body={Array.isArray(body) ? ListBody(body, listType, listTitle) : body} />} />
  );
}
