
export function flattenValues(values) {
    const items = (values||[]).reduce((prev, curr, i)=>{
      console.log("CURR: ", curr);
        const item = Object.entries(curr).reduce((x, [k,v]) => {
          x[k] = [...x[k]||[], v];
          return x;
        }, prev);
        prev = item;
        return prev;
      }, {});
    return items;
}
