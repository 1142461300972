import * as React from 'react';
import Typography from '@mui/material/Typography';


export default function FieldLabel({label, required}) {
    return(
      <>
      {label && label !== "" ? <Typography>
            {label}{required ? <span style={{color: 'red'}}>*</span> : ""}</Typography> : ""}
      </>
    );
  }