import * as React from 'react';
// web.cjs is required for IE11 support
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material';

//icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

// animations
import Fade from '@mui/material/Fade';

// elements
import {FAQCustomers} from "views/index-sections/index-content/FAQCustomers.js";
import QATree from "components/elements/QATree";
import ImageDisplay from "components/elements/ImageDisplay"

// styling
import "assets/scss/pages/faq.scss";


type Props = {
  pageRef: any,
  theme: {img: string},
  animate: any,
  currentPage: any
}


export default function FAQ(props: Props) {
  const theme = useTheme();
  return (
    <Container ref={props.pageRef} id="faq"
    sx={{display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center',
    minWidth: '100%', maxWidth: '100%', minHeight: '90vh', backgroundColor: theme.palette.primary.main}}>
    <Stack sx={{ mt: 10, mb: 10, p: 5,
        flexGrow: 1, boxShadow: 10,
        width: {xs: '80vw', md: '45vw'}, minWidth: 100, maxWidth: 700,
        minHeight: '50vh', overflowY: 'auto',
        backgroundColor: "rgba(0, 0, 0, 0.6)", color: '#FFF' }}>
        <Typography variant="h4" sx={{mb: 5}}>Frequently Asked Questions</Typography>
        <QATree
            sx={{color: "rgba(255, 255, 255, 0.6)"}}
            activeColor='#FFF'
            collapseIcon={<ExpandMoreIcon />}
            expandIcon={<KeyboardArrowRightIcon />}
            endIcon={<CloseIcon />}
            map={FAQCustomers}
            categoryClassName="faq-category"
            titleClassName="faq-question"
            bodyClassName="faq-answer"
            ariaLabel="FaqTree"
            />
    </Stack>
    <Fade in={props.animate} timeout={1000}>
      <Box sx={{mt: 10, ml:10, width: '50vh', height: '50vh', maxWidth: '800px', maxHeight: '800px',
                display: { xs: 'none', lg: 'block'},}}>
          <ImageDisplay url={props.theme.img} boxShadow={10} />
      </Box>
    </Fade>
    </Container>
  );
}
