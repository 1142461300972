import * as React from 'react';
import * as lodash from 'lodash';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormContext, Controller } from "react-hook-form";

import FieldLabel from './FieldLabel';


/*
<FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
      <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
    </FormGroup>
            */

export default function RHFSelectInput ({name, label, defaultValue, options, errors, ...props}) {
    const { register, control } = useFormContext();
    //console.log("SELECT DEFAULT VALUE", defaultValue);
    const err = lodash.get(errors||{}, name);

    return (
        <><FieldLabel label={label} required={props.required} />
        <Controller
        render={({ field }) => (
            <Select fullWidth
            error={Boolean(err)} 
            {...field}>
                {(options||[]).map((option, i)=> {
                    return (
                    <MenuItem value={option}>{option}</MenuItem>
                    )
                })
                }
            </Select>
        )}
        name={name}
        rules={{ }}
        control={control}
        defaultValue={`${defaultValue}`}
        />
        </>
    );
}
