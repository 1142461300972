import React from "react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//components

type Props = {
  title: string,
  body: string|JSX.Element,
  img: string,
  width: string | {xs: string, md: string, lg: string}
}

export default function ContentDisplaySimple(props: Props) {

  return (
    <Stack sx={{position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start',
              width: '100%', minHeight: '80vh',}}>
    <Box sx={{width: props.width, flexWrap: 'wrap', m:10,
              p: 5, boxShadow: 5, backgroundColor: '#FFF'}}>
      <Typography variant="h4" sx={{textTransform: 'capitalize', mb: 4}}>{props.title}</Typography>
      <Typography className="info-section-body">
      {props.body}
      </Typography>
    </Box>
    </Stack>
  );
}
