import * as React from 'react';
import {useState} from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// styling

//icons
import ForwardIcon from '@mui/icons-material/Forward';

// elements
import ImageDisplay from "components/elements/ImageDisplay"
import {PaymentOptions} from "./index-content/PaymentOptions.js";
import InfoDialog from 'components/elements/InfoDialog';
import StyledButtonFilled from "components/elements/buttons/StyledButtonFilled";
import StyledButtonFilled2 from "components/elements/buttons/StyledButtonFilled2";
import StyledButtonFilledBlue from "components/elements/buttons/StyledButtonFilledBlue";


type Props = {
    theme: {img: string, buttonColor?: string},
    pageRef: any,
    animate: boolean
}


const ImgComponent: any = React.forwardRef((props: Props, ref) => {
return (
<Box {...props} ref={ref}
sx={{mt: 1, mr: 10, width: '30vw', height: 858, maxWidth: 500, display: { xs: 'none', lg: 'block'},}}>
<ImageDisplay url={props.theme.img} boxShadow={5} />
</Box>
);
});

const PaymentButton: any = (({k, v, setShowDialog, setDialogInfo, buttonSx, label})=>{
  const dialogFn = ()=>{
    setShowDialog(true);
    setDialogInfo({
      title: k,
      body: v.info
    });
  }
  return(
    <StyledButtonFilled2 href={v.link} target="_blank" variant="contained"
    onClick={()=>{v.link === "" ? dialogFn() : void(0) }}
    startIcon={v.icon}
    endIcon={<ForwardIcon />}
    sx={{p: 1, ...buttonSx}}>
      {label}
    </StyledButtonFilled2>
  );
})

const PaymentCard: any = ({k, v, palette, setShowDialog, setDialogInfo}) => {
return(
<Button href={v.link} target = "_blank" sx={{ textTransform: 'none' }}>
<Card sx={{ minWidth: 275, width: '100%', borderRadius: 0, }}>
<CardContent>
<Typography variant="h6" component="div" align="left" sx={{fontWeight: 'bold'}}>
    {k}
</Typography>
<Typography variant="body1" align="left">
  {v.info}
</Typography>
</CardContent>
<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
{v.label === "" ? "" : <PaymentButton 
k={`Pay Us By ${k}`} v={v}
label={v.label}
buttonSx={{borderRadius: 0, width: '100%', fontSize: '1.0rem'}}
setShowDialog={setShowDialog} setDialogInfo={setDialogInfo}
/>}
</Box>
</Card>
</Button>
)
}

const displayDesktop = {xs: 'none', sm: 'inline-flex'};
const displayMobile = {xs: 'inline-flex', sm: 'none'};
  
const PaymentMenuMobile: any = (props, ref) =>{
return(<><InfoDialog 
  show={props.showDialog} setShow={props.setShowDialog} 
  title={props.dialogInfo.title}
  body={props.dialogInfo.body}
  />
<Stack spacing={4} sx={{display: displayMobile}}>
{Object.entries(PaymentOptions).map(([k, v], i) => {
  return <Box sx={{display: 'flex', justifyContent: 'center'}}><PaymentButton
  k={`Pay Us By ${k}`} v={v}
  label={k}
  buttonSx={{borderRadius: 10, width: {xs: '50vw', sm: '250px'}, fontSize: '1.5em'}}
  setShowDialog={props.setShowDialog} setDialogInfo={props.setDialogInfo} />
  <Box sx={{position: 'absolute', justifyContent: 'center'}}>
  <Typography align="justify" sx={{position: 'relative', top: 60,
  color: props.palette.error.main, fontSize: '0.8em'}}>{v.disclaimer}</Typography>
  </Box>
  </Box>
  ;
})}
</Stack>
</>
);
};

const PaymentMenuDesktop: any = (props, ref) =>{
return(
  <Stack spacing={1} sx={{display: displayDesktop, maxWidth: 500, width: {sm: '70%', md: '50%', lg: '40%'}}}>
  {Object.entries(PaymentOptions).map(([k, v], i) => {
    if(v.link.startsWith("tel")) {
      v = {...v, link: ""}
    }
    return(
      <PaymentCard k={k} v={v} palette={props.palette} 
      setShowDialog={props.setShowDialog} setDialogInfo={props.setDialogInfo}
    />
    );
    
  })}
  </Stack>
);
};

const PaymentMenu: any = React.forwardRef((props, ref)=>{
  return(
<Stack ref={ref} sx={{width: '90%',
display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start'}}>
  <Typography variant="h4" sx={{textTransform: 'capitalize', mb: 6,}}>Payment Options</Typography>
  <Box sx={{width: '100%', display: 'flex',
    alignItems: 'flex-start', justifyContent: 'center',}}>
    <ImgComponent {...props} />
    <>
      <PaymentMenuDesktop {...props} />
      <PaymentMenuMobile {...props} />
    </>
  </Box>
</Stack>
  );
})



export default function Payments(props: Props) {
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({title: "", body: ""});

  return (
    <Container ref={props.pageRef} id="payments" sx={{p: 10,
    display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center',
    minWidth: '100%', maxWidth: '100%', minHeight: '95vh',
    backgroundColor: theme.palette.info.main,
    }}>
      <Stack spacing={10} sx={{alignItems: 'center'}}>
        <StyledButtonFilledBlue
        href={'https://baysidecredit.accountportalonline.com/'}
        sx={{width: '100%', maxWidth: 300, borderRadius: 0, boxShadow: 2,
        letterSpacing: 1.2, fontSize: '1.2rem', fontWeight: 'bold', textTransform: 'capitalize'}}>
          Manage My Account
        </StyledButtonFilledBlue>

        <PaymentMenu palette={theme.palette} 
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogInfo={dialogInfo}
        setDialogInfo={setDialogInfo}
        {...props} />
      </Stack>
    </Container>

  );
}
