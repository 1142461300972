import React from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import RHFTextInput from './RHFTextInput';
import RHFSelectInput from './RHFSelectInput';
import RHFMaskedInput from './RHFMaskedInput';
import FieldLabel from './FieldLabel';


type Props = {
  id?: string,
  name?: string,
  states?: string[],
  required?: boolean,
  errors?: any,
  defaultState?: any,
};

const defaultStates = ["CA", "AZ", "NV", "UT"];
const defaultStatesFullName = ["California", "Arizona", "Nevada", "Utah"];
const allStates = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
const allStatesFullName = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];

export const addressSchema = {
  'Street': 'string',
  'City': 'string',
  'State': 'string',
  'Zip': 'string'
}


export default function RHFAddressInput(props: Props) {
  const street_id = `${props.name}.Street`;
  const city_id = `${props.name}.City`;
  const state_id = `${props.name}.State`;
  const zip_id = `${props.name}.Zip`;
  const states = (props.states === undefined) ? allStates: props.states;
  const defaultState = props.defaultState||"CA";


  return(
    <Stack><FieldLabel label={props.label} required={props.required} />
      <Stack spacing={0} sx={{ml: 2}}>
        <RHFTextInput name={street_id} required={props.required} label={'Street'} errors={props.errors} />
        <RHFTextInput name={city_id} required={props.required}  label={'City'} errors={props.errors} />
        <Stack direction="row" spacing={1} sx={{alignItems: 'space-between'}}>
          <Box sx={{width: '50%'}}>
            <RHFSelectInput name={state_id} required={props.required} 
            label={'State'} defaultValue={defaultState}
            options={states} errors={props.errors} />
          </Box>
          <Box sx={{width: '50%'}}>
          <RHFMaskedInput name={zip_id} id={zip_id} required={props.required} label={'Zipcode'} mask={'99999'} errors={props.errors} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
