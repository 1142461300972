import React from "react";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// animations
import Fade from '@mui/material/Fade';

// elements
import ImageDisplay from "components/elements/ImageDisplay";
import StyledButtonFilled from "components/elements/buttons/StyledButtonFilled";



type Props = {
  theme: {img: string},
  pageRef: any,
  animate:boolean
}

export default function Dealers(props: Props) {

  return (
    <Container ref={props.pageRef} id="dealers" sx={{p: 10,
    display: 'inline-flex', alignItems: 'flex-start', justifyContent: 'center',
    minWidth: '100%', maxWidth: '100%', minHeight: '95vh',}}>
        <Stack sx={{maxWidth: 500, width: {xs: '80%', sm: '90%', md: '35%', lg: '30%'}, pb: 5,}}>
        <Typography variant="h4" sx={{textTransform: 'capitalize', mb: 4}}>For Our Dealers</Typography>
        <div className="info-section-body">
        <p>We proudly offer first-class products and support for our dealers and their customers. Our experienced representatives are here to assist our dealer partners to surpass the auto financing needs of their customers. Our unique selection criteria places a greater emphasis on customer’s ability to pay and stability and less emphasis on their credit alone. We work closely with our dealers to closely examine each deal and provide our dealer partners with a “yes” when others may say “no”.
        </p>
        <p>We view our dealers as partners and work hard to provide long-term relationships with our dealers best interest in mind. We offer our decades of experience in the non-prime auto marketplace to our dealers and our upper management is closely involved with our dealers.
        </p>
        <p>We know that funding time is a critical factor for our dealers so we strive to offer same-day and next-day funding whenever possible. To join our carefully selected list of dealer partners, please complete the contact form below and a market representative will be in touch with you shortly.
        </p>
        </div>
        <br />
        <StyledButtonFilled sx={{width: {xs: '100%', sm: 250}, borderRadius: 1}}
        href="/index/contactus"
        >Are you a new dealer?</StyledButtonFilled>
      </Stack>
      <Fade in={props.animate} timeout={1000} >
        <Box sx={{mt: 12, ml: 10, maxWidth: 500, maxHeight: 500, width: '35vw', height: '50vh',
                  display: { xs: 'none', md: 'block'},}}>
            <ImageDisplay url={props.theme.img} boxShadow={5} />
        </Box>
      </Fade>
    </Container>
  );
}
