import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// icons
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';

// elements
import AltPage from "components/layouts/AltPage"
import ContentDisplaySimple from "components/elements/ContentDisplaySimple"
import InfoDialog from "components/elements/InfoDialog";
import LoadingDisplay from "components/elements/LoadingDisplay";
import BlackFilter from "components/filters/BlackFilter";
import StyledButtonImage from "components/elements/buttons/StyledButtonImage";

// styling

const url = process.env.REACT_APP_API_URL+"/fedex/label/";
const imgUrl = process.env.PUBLIC_URL + "/img/documents2.jpg";
const fedexLogoImgurl = process.env.PUBLIC_URL + "/img/fedexlogo_large.png";


//const errMsg = <>An error occurred while fetching label. <br />Please contact a Bayside Credit representative at (949) 284-8818</>;
const errMsgs = {
  'default': <>An error occurred while fetching label. <br />Please contact a Bayside Credit representative at (949) 284-8818</>,
  'Expired status': <>This label is expired. <br />Please contact a Bayside Credit representative at (949) 284-8818 if you need a new label.</>
}


const fedex_pickup_types = {
  "dropoff": {
    icon: <LocalShippingIcon fontSize="inherit" />,
    label: "DROP OFF AT FEDEX",
    title: "Drop Off At FedEx",
    url: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
    url_label: "Find a FedEx Dropoff Location",
    img: process.env.PUBLIC_URL + "/img/package_small.jpg"
  },
  "contact": {
    icon: <ContactPhoneIcon fontSize="inherit" />,
    label: "SCHEDULE A PICKUP",
    title: "Contact FedEx to Schedule a Pickup",
    url: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
    url_label: "Contact FedEx",
    img: process.env.PUBLIC_URL + "/img/phone_small.jpg"
  },
  "schedule": {
    icon: <AccessTimeIcon fontSize="inherit" />,
    label: "RECURRING PICKUP",
    title: "Ship as Part of A Recurring FedEx Pickup",
    url: 'https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html',
    url_label: "Schedule a FedEx Freight Pickup",
    img: process.env.PUBLIC_URL + "/img/calendar_small.jpg"
  }
}


const downloadImage = (blobUrl: any, name: string) => {
  const elem = document.createElement("a");
  elem.download = name;
  elem.href = blobUrl;
  document.body.appendChild(elem);
  elem.click();
  elem.remove();
}

const printImage = (data: any) => {
  let w = window.open();
  w.document.write('<img src="' + data + '" width="50%" onload="window.print();window.close()" />');
  w.print();
  w.close();
}

const openPDF = (pdf_url: any) => {
  let w = window.open(pdf_url);
}

/*
const displayImg = () => {
  return(
    <>
    <div id="adobe-dc-view" style="height: 360px; width: 500px;"></div>
    <script src="https://documentcloud.adobe.com/view-sdk/viewer.js"></script>
    <script type="text/javascript">
      document.addEventListener("adobe_dc_view_sdk.ready", function(){
        var adobeDCView = new AdobeDC.View({clientId: "<YOUR_CLIENT_ID>", divId: "adobe-dc-view"});
        adobeDCView.previewFile({
          content:{ location:
            { url: "https://documentcloud.adobe.com/view-sdk-demo/PDFs/Bodea%20Brochure.pdf"}},
          metaData:{fileName: "Bodea Brochure.pdf"}
        },
        {
          embedMode: "SIZED_CONTAINER"
        });
      });
    </script>
    </>
  )
}
*/


export default function FedExLabels() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const {id, authkey} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [pickupType, setPickupType] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [labels, setLabels] = useState({
    "dropoff": null,
    "contact": null,
    "schedule": null
  })

  async function fetchLabel(option: string) {
    const fedex_label_url = `${url}${id}?pickup=${option}&bt=${authkey}`;
    try {
      const res = await fetch(fedex_label_url);
      if (res) {
        const contentType = res.headers.get("content-type");
        //console.log("content type", contentType);
        if(contentType==='application/pdf') {
          const blob = await res.blob();
          const blobUrl = window.URL.createObjectURL(blob);
          setLabels((prev) => ({...prev, [option]: blobUrl}));
        }
        else {
          res.json().then(data => {
            //console.log(data);
            if(data['error']) {
              setError(errMsgs[data['error']]);
              setIsLoaded(true);
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    setIsLoaded(false);
  }, [pickupType]);

  function handleDisplayLabel(option) {
    setPickupType(option);
    setIsLoaded(false);
    setError(null);
    setShowDialog(true);
    fetchLabel(option);
  }

  function handleImageError() {
    setIsLoaded(true);
    setError(errMsgs['default']);
  }

  function DisplayStatus() {
    return(
      <>
      { error && <Typography sx={{color: '#FF0000' }}>{error}</Typography>}
      {!isLoaded && <LoadingDisplay sx={{pt: 5}} />}
      </>
    )
  }

  /*
  <Button component={'a'} startIcon={<DownloadIcon />}
    onClick={()=>downloadImage(labels[pickupType], `bayside-fedex-label-${pickupType}-${id}`)} >Download</Button>
  */

  function DisplayLabel() {
    const fedex_pickup_type = fedex_pickup_types[pickupType]||{};
    return(
      <Box sx={{alignItems: 'center', justifyContent: 'center'}}>
        <Stack spacing={3} sx={{display: error === null ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center'}}>
          <Box sx={{display: isLoaded ? 'flex' : 'none', width: '100%', justifyContent: 'space-evenly'}}>
            <Button component={'a'} startIcon={<PrintIcon />}
              onClick={()=>openPDF(`${url}${id}?pickup=${pickupType}&bt=${authkey}`)} >Open Shipping Label</Button>
            <Button
              startIcon={<img alt='fedex' height="10px" src={fedexLogoImgurl} />}
              onClick={()=>window.open(fedex_pickup_type.url, '_blank')}
              >
                {fedex_pickup_type.url_label}
                </Button>
          </Box>
          <div style={isLoaded ? {width: '100%', height: '80vh'} : {height: '0vh'}}>
          {labels[pickupType] && <embed
            title="fedex-label"
            id="fedex-label"
            style={{height: '100%', width: '100%'}}
            onLoad={()=>setIsLoaded(true)}
            onError={()=>handleImageError()}
            src={labels[pickupType]} />
          }
          </div>
        </Stack>
        <DisplayStatus />
      </Box>
    )
  }

  function PickupChoices() {
    const fedex_pickup = fedex_pickup_types[pickupType]||{};
    return(
      <>
      Application Id: {id}
        <InfoDialog show={showDialog} setShow={setShowDialog} maxWidth="sm"
                    title={fedex_pickup.title} body={<DisplayLabel />} />
        <Stack spacing={{xs: 3, sm: 5, xl: 10}} direction={isMobile ? 'column' : 'row'} 
              sx={{width: '100%', pt: 5, pb: 5,
                  display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {
          Object.entries(fedex_pickup_types).map(([k, v], i) => {
            return(
              <StyledButtonImage
                sx={{
                backgroundImage: `url(${v.img})`, backgroundSize: 'cover', zIndex: 0}}
                onClick={()=>handleDisplayLabel(k)}>
                  <BlackFilter strength={0.6} />
                  <Box sx={{border: '2px solid #FFF', p: 1, m: 1,
                height: {xs: '15vh'}, width: {xs: '50vw', md: '40vw', lg: '15vw'}, 
                display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <Typography sx={{fontSize: {xs: '1.4rem'}, textTransform: 'uppercase'}}>{v.label}</Typography>
                    </Box>
              </StyledButtonImage>
            )
          })
          }
        </Stack>
        <Typography sx={{color: '#FF0000'}}>
        * A $25 shipping fee will be automatically deducted from your funding proceeds on Application #{id} for use of this service. <b>Application #{id} must be included.</b> A single label can be used to ship up to 5 funding packages.
        &nbsp;<a href='/fedex/info/bestpractices' target="_blank">Click here for best shipping practices.</a>
        </Typography>
      </>
    );
  }

  return (
    <AltPage useDefaultNavbar={false} img={imgUrl} content={<ContentDisplaySimple
                          img={imgUrl}
                          width={{xs: '90vw', md: '80vw', lg: '60vw'}}
                          title={`Get A FedEx Shipping Label`}
                          body={<PickupChoices />} />} />
  );
}
