import React from "react";
import * as lodash from 'lodash';
import InputMask from "react-input-mask";
// import InputMask from "react-text-mask";
import { useFormContext, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';


import FieldLabel from './FieldLabel';


type Props = {
  id?: string,
  name?: string,
  variant?: string,
  required?: any,
  control?: any,
  register?: any,
  mask?: any,
  errors?: any,
  rules?: any
  label?: any,
  maxLength?: any
};


export default function RHFMaskedInput({label, name, mask, maxLength, errors, ...props}: Props) {
  const input_name = name||"default";
  const { register, control, getFieldState } = useFormContext();
  const err = lodash.get(errors||{}, input_name);

  return(
    <><FieldLabel label={label} required={props.required} />
    <Controller
          control={control}
          name={input_name}
          rules={{
            required: {
              value: true,
              message: "Required"
            }
          }}
          render = {({field: {ref, value, onChange},
            fieldState: {isDirty, error},
            })=> (<InputMask mask={mask} value={value} onChange={onChange}>
              {() => <TextField fullWidth 
              inputProps={{ref}}
              required={props.required} 
              error={Boolean(err)}
              helperText={err && err.message}
              />}
          </InputMask>)}
        />
    </>
  );
}
