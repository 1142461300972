import * as React from 'react';
import { Controller } from "react-hook-form";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import StepItemInput from './StepItemInput';
import FieldLabel from './FieldLabel';


/*
<FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
      <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
    </FormGroup>
            */

export default function StepRadioGroupInput ({register, control, name, page, options, step, defaultValue, ...props}) {
    const rname = `${name}`;
    //const result = useWatch({control, name: rname, defaultValue: defaultValue});
    //console.log("RADIO DEF VQALUE", defaultValue);

    return (
        <FormControl>
            <FieldLabel label={name} required={props.required} />
            <Controller
                control={control}
                name={rname}
                render={({ field }) => (
            <RadioGroup {...field}
            >
                {
                    options.map((option, i)=>{
                        return(
                            <>
                            <FormControlLabel control={<Radio value={option} />} label={option} />
                            {
                                step!==undefined&&field.value===option&&<StepItemInput item={(step||{})[option]} />
                            }
                            </>
                        );
                    })
                }
            </RadioGroup>
            )}
            defaultValue={defaultValue}
            />
        </FormControl>
    );
}
