import React from "react";
import { IMaskInput } from 'react-imask';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';


interface TextMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const TextMaskCustom = React.forwardRef<HTMLElement, TextMaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as any}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

type Props = {
  id: string,
  name: string,
  value: any,
  onChange: any,
  variant?: string,
  required?: any
};

/*
<Input
  variant={props.variant}
  value={props.value}
  onChange={props.onChange}
  name={props.name}
  id={props.id}
  inputComponent={TextMaskCustom as any}
/>
*/

export default function PhoneNumberInput(props: Props) {
  return(
    <TextField
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      {...props}
    />
  );
}
