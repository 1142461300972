import * as React from 'react';

//react-hook-form
import { useFormContext } from "react-hook-form";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//input types
// elements
import RHFDynamicInput from 'components/forms/form-input-types/reacthookform/RHFDynamicInput';
import ItemInput from 'components/forms/form-input-types/reacthookform/RHFItemInput';
import StepCheckboxGroupInput from 'components/forms/form-input-types/reacthookform/StepCheckboxGroupInput';
import StepRadioGroupInput from 'components/forms/form-input-types/reacthookform/StepRadioGroupInput';
import StepWatcher from 'components/forms/form-input-types/reacthookform/StepWatcher';
import ListItemInput from 'components/forms/form-input-types/reacthookform/RHFListItemInput'


interface Props {
    meta?: any,
    values?: any,
    trigger?: any,
    control?: any,
    name?: any,
    register?: any,
    form?: any,
    page?: any,
    errors?: any,
    pageErrors?: any
}


export default function FieldsGenerator({page, meta, register, control, errors}: Props) {  
  const { getValues, setValue } = useFormContext();
  const values = getValues();


  return(
    <>
    {

    meta.map((item, i)=> {
      const key = item.key? item.key: item.label;
      if (item.type === 'item') {
        return(
          <ItemInput register={register} control={control} name={key} itemMeta={item} errors={errors||{}} />
        );
      }
      else if (item.type === 'listitem') {
        return(
          <ListItemInput register={register} control={control} name={key} itemMeta={item} errors={errors||{}} />
        );
      }
      else if ((item.type === 'checkboxGroup')) {     
        return (<>
          <StepCheckboxGroupInput 
          name={key}
          label={item.label}
          options={item.options}
          step={item.step}
          register={register}
          control={control}
          required={item.required}
          defaultValue={item.defaultValue}
          rules={item.rules}
          />
          </>
        );
      }
      else if ((item.type === 'radio')) {     
        console.log("STEP RADIO DEF VAL", values[key]);
        return (<>
          <StepRadioGroupInput 
          page={page}
          name={key}
          label={item.label}
          options={item.options}
          step={item.step}
          register={register}
          control={control}
          defaultValue={item.options[item.defaultValue]}
          rules={item.rules}
          required={item.required}
          />
          </>
        );
      }
      else {
        return (<Box>
          <Stack direction={item.layout||"column"} >
            <Box sx={{width: item.width||'100%'}}>
              <RHFDynamicInput 
                key={key+page}
                name={key}
                label={item.label}
                field_type={item.type}
                options={item.options}
                register={register}
                control={control}
                defaultValue={values[key]}
                required={item.required}
                rules={item.rules}
                errors={errors||{}}
                states={item.states}
              />
            </Box>
          {item.step&&<StepWatcher register={register} control={control}
            watchName={key} defaultValue={values[key]} triggerKey={false} step={item.step}
            errors={errors} />
          }
          </Stack>
          </Box>
        );
      } 
    }
    )
  }
    </>
  )
}
