import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const StyledButtonImage: any = styled(Button)(({ theme }) => ({
    cursor: 'pointer',
    backgroundSize: 'cover',
    borderRadius: 0,
    color: theme.palette.primary.contrastText,
    transition: `${theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,})}`,
    '&:hover': {
      transform: 'scale(1.1)',
      opacity: 0.8,
    },
  }));
export default StyledButtonImage;
