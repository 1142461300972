import React from "react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//components


type Props = {
  width: string | number,
  content: JSX.Element
}

export default function JSXDisplaySimple(props: Props) {

  return (
    <Stack sx={{position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start',
              width: '100%', minHeight: '80vh',}}>
    <Box sx={{width: props.width, flexWrap: 'wrap', m:10,
              p: 5, boxShadow: 5, backgroundColor: '#FFF'}}>
      {props.content}
    </Box>
    </Stack>
  );
}
