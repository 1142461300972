import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";


// styles
import "assets/css/fonts.css";
import "assets/scss/core/global.scss";
import "assets/scss/core/animations.scss";


// pages
import Home from "views/Home";
import GeneralDocumentPage from "views/GeneralDocumentPage";
import GeneralJSXDisplay from "views/GeneralJSXPage";

import AboutUs from "views/content/AboutUs";
import AboutUs2 from "views/content/AboutUs2";

import ParticipationGrid from "views/pages/ParticipationGrid";

import {EsignatureResponses} from "views/content/EsignatureResponses";

import {DealerStipulations} from "views/content/DealerStipulations";
import DealerAgreementForm from "views/pages/DealerAgreementForm";

import FedexLabels from "views/pages/FedexLabels";
import {FedexContent} from "views/content/FedexContent";


ReactDOM.render(
  <BrowserRouter>
    <Routes>
        <Route path="/participation/:id/:authkey" element={<ParticipationGrid />} />

        <Route path="/dealers/stipulations/:key" element={<GeneralDocumentPage map={DealerStipulations} />} />
        <Route path="/dealers/agreement" element={<DealerAgreementForm />} />

        <Route path="/esignature/:key" element={<GeneralDocumentPage map={EsignatureResponses} />} />
        
        <Route path="/fedex/label/:id/:authkey" element={<FedexLabels />} />
        <Route path="/fedex/info/:key" element={<GeneralDocumentPage map={FedexContent} />} />

        <Route path="/aboutus" element={<GeneralJSXDisplay content={<AboutUs />} />} />
        <Route path="/aboutus2" element={<GeneralJSXDisplay content={<AboutUs2 />} />} />
        
        <Route path="/index/:page" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
