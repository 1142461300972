import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//elements
import ImageDisplay from "components/elements/ImageDisplay";


// window size


const AboutUsMap = {
  'Our Mission': {
    img: process.env.PUBLIC_URL+"/img/dashboard1.jpg",
    content: <Typography align="justify">To provide competitive and convenient financing solutions to auto dealers and their customers.</Typography>
  },
  'Our Vision': {
    img: process.env.PUBLIC_URL+"/img/car3.jpg",
    content: <Typography align="justify">To distinguish Bayside Credit as the premier solution for automotive financing in the  United States; providing a world class user experience through innovative solutions and industry leading customer service.</Typography>
  },
  'Our Values': {
    img: process.env.PUBLIC_URL+"/img/driving4.jpg",
    content:<Stack sx={{alignItems: 'center'}}>
  <Typography>Integrity</Typography>
  <Typography>Discipline</Typography>
  <Typography>Excellence</Typography>
  <Typography>Resilience</Typography>
  <Typography>Innovation</Typography>
  <Typography>Compassion</Typography>
  </Stack>}
}


const AboutUs = ()=> {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const titleBorders = isMobile ? "──": "──────";
  
  return(<Stack sx={{width: '100%', mb: 10}}>

  <Typography variant="h4" sx={{textTransform: 'capitalize', mb: 4}}>About Us</Typography>
  <Stack sx={{alignItems: 'center', border: '0px solid red', width: '100%'}}>
  {
    Object.entries(AboutUsMap).map(([k, v])=> {
      return(
        <>
        <Box sx={{m: 5, width: {xs: '50vw', sm: '40vh'}, height: {xs: '50vw', sm: '40vh'}, maxWidth: '350px', maxHeight: '350px'}}>
          <ImageDisplay url={v.img} boxShadow={2} borderRadius={100} />
        </Box>
        <Typography sx={{mt: 5, fontWeight: 'bold'}}>{titleBorders}&nbsp;&nbsp;&nbsp; {k} &nbsp;&nbsp;&nbsp;{titleBorders}</Typography>
        <Box sx={{mt: 2, mb: 5, maxWidth: '500px'}}>
          {v.content}
        </Box>
        </>
      )
    })
  }
  </Stack>
</Stack>);
};
export default AboutUs;
  