import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const StyledButtonFilled: any = styled(Button)(({ theme }) => ({
    cursor: 'pointer',
    position:"relative",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transition: `${theme.transitions.create(['backgroundColor', 'transform'], {
      duration: theme.transitions.duration.standard,})}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      transform: 'scale(1.1)'
    },
  }));
export default StyledButtonFilled;
