import React from "react";
import Stack from '@mui/material/Stack';

import RHFTextInput from './RHFTextInput';
import RHFMaskedInput from './RHFMaskedInput';
import FieldLabel from './FieldLabel';


type Props = {
  id?: string,
  name?: string,
  states?: string[]
};

export const achSchema = {
  'Account Name': 'string',
  'Bank Name': 'string',
  'Account Number': 'string',
  'Routing Number': 'string'
}


export default function RHFACHPaymentInput(props: Props) {
  const account_name_id = `${props.name}.Account Name`;
  const bank_name_id = `${props.name}.Bank Name`;
  const account_number_id = `${props.name}.Account Number`;
  const routing_number_id = `${props.name}.Routing Number`;
  

  return(
    <Stack><FieldLabel label={props.label} required={props.required} />
    <Stack spacing={0} sx={{ml: 2}}>
    <RHFTextInput name={account_name_id} label={'Account Name'} />
    <RHFTextInput name={bank_name_id} label={'Bank Name'} />
    <RHFTextInput name={account_number_id} label={'Bank Account Number'} maxLength={12} />
    <RHFMaskedInput name={routing_number_id} label={'Routing Number'} mask={'999999999'}/>
    </Stack>
    </Stack>
  );
}
