export const DealerStipulations = {
  "ca": {
    title: "California Standard Stipulations",
    body: [
      "Valid CA DL or ID (Suspended License ok w/ $250 fee – no DUI) or Matricula Consular or International ID.",
      "Proof of Residence (dated within 30 days of approval) – Utility or Phone Bill / Mail in Borrower(s) Name / Lease or Rental Agreement. (App and CoApp)",
      "Original Assigned Contract",
      "Agreement to Provide Insurance",
      "Proof of Income (App and Coapp)",
      "Notice to Co-signer (If applicable)",
      "Optional Products and Services (If applicable)",
      "Guarantee of Title",
      "Original, signed credit application",
      "Contract Cancellation Option Agreement",
      "262 or Odometer Disclosure",
      "Foreign Language Acknowledgement",
      "Buyer’s Guide",
      "Proof of full coverage insurance with maximum of $500 deductibles on comprehensive and collision",
      "Credit Score Disclosure",
      "Signed NMVTIS, Carfax, Autocheck (with additional signatures for any adverse reporting, such as rental and mileage discrepancies)",
      "ROS",
      "Catalytic Converter Waiver Agreement/Etching"
      ]
    },
  "co": {
    title: "Colorado Standard Stipulations",
    body: [
      "CO DL or Matricula Consular or International ID. CO ID ok w/ $250 fee",
      "Proof of Residence (dated within 30 days of approval) – Utility or Phone Bill / Mail in Borrower(s) Name / Lease or Rental Agreement. (App and CoApp)",
      "Original Assigned Contract",
      "Agreement to Provide Insurance",
      "Proof of Income (App and Coapp)",
      "Title and Registration Application",
      "Notice to Co-signer (If applicable)",
      "Original, signed credit application",
      "262 or Odometer Disclosure",
      "Buyer’s Guide",
      "Proof of full coverage insurance with maximum of $500 deductibles on comprehensive and collision",
      "Credit Score Disclosure",
      ]
    },
  "az": {
    title: "Arizona Standard Stipulations",
    body: [
      "AZ DL or Matricula Consular or International ID. AZ ID ok w/ $250 fee",
      "Proof of Residence (dated within 30 days of approval) – Utility or Phone Bill / Mail in Borrower(s) Name / Lease or Rental Agreement. (App and CoApp)",
      "Original Assigned Contract",
      "Agreement to Provide Insurance",
      "Proof of Income (App and Coapp)",
      "Title and Registration Application",
      "Notice to Co-signer (If applicable)",
      "Original, signed credit application",
      "262 or Odometer Disclosure",
      "Buyer’s Guide",
      "Proof of full coverage insurance with maximum of $500 deductibles on comprehensive and collision",
      "Credit Score Disclosure",
      ]
    },
  "nv": {
    title: "Nevada Standard Stipulations",
    body: [
      "NV DL or Matricula Consular or International ID. NV ID ok w/ $250 fee",
      "Nevada Vehicle Inspection for Vehicles with 75k+ miles",
      "Proof of Residence (dated within 30 days of approval) – Utility or Phone Bill / Mail in Borrower(s) Name / Lease or Rental Agreement. (App and CoApp)",
      "Original Assigned Contract",
      "Agreement to Provide Insurance",
      "Proof of Income (App and Coapp)",
      "DRS",
      "Notice to Co-signer (If applicable)",
      "Original, signed credit application",
      "262 or Odometer Disclosure",
      "Buyer’s Guide",
      "Proof of full coverage insurance with maximum of $500 deductibles on comprehensive and collision",
      "Credit Score Disclosure",
      ]
    },
  "ut": {
    title: "Utah Standard Stipulations",
    body: [
      "UT DL or Matricula Consular or International ID. UT ID ok w/ $250 fee",
      "Proof of Residence (dated within 30 days of approval) – Utility or Phone Bill / Mail in Borrower(s) Name / Lease or Rental Agreement. (App and CoApp)",
      "Original Assigned Contract",
      "Agreement to Provide Insurance",
      "Proof of Income (App and Coapp)",
      "Title Application",
      "Notice to Co-signer (If applicable)",
      "Original, signed credit application",
      "262 or Odometer Disclosure",
      "Buyer’s Guide",
      "Proof of full coverage insurance with maximum of $500 deductibles on comprehensive and collision",
      "Credit Score Disclosure",
      ]
    },
}