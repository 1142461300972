export const EsignatureResponses = {
    "success": {
        title: "Document Signed",
        body: "Thank you for your business!"
    },
    "cancel": {
        title: "Document Cancelled",
        body: ""
    },
    "error": {
        title: "Document Signing Error",
        body: "Something went wrong in the process of signing the document. Please contact a Bayside Credit representative."
    },
    "save": {
        title: "Document Saved",
        body: "You can access it again at the same document link provided in the email."
    }
};
