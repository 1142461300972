import * as React from 'react';

// icons
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MoneyIcon from '@mui/icons-material/Money';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


//const map_html = '<div class="mapouter"><div class="gmap_canvas"><iframe width="600" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=Irvine,%20CA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://fmovies-online.net"></a><br><style>.mapouter{position:relative;text-align:right;height:300px;width:600px;}</style><a href="https://www.embedgooglemap.net">embed code for google map</a><style>.gmap_canvas {overflow:hidden;background:none!important;height:300px;width:600px;}</style></div></div>'



export const PaymentOptions = {

  "Online": {
    icon: <CloudUploadIcon sx={{fontSize: 'inherit'}} />,
    link: "https://www.paynearme.com/baysidecredit",
    info: <>Pay online through the PayNearMe portal. Processing fees apply.</>,
    disclaimer: "* Processing fees may apply",
    label: "Open Portal"
  },
  "Auto Pay": {
    icon: <AccountBalanceIcon sx={{ fontSize: 'inherit' }} />,
    info: <>Set up FREE and automatic ACH payments from your bank account with the form below.</>,
    link: "/docs/Autopay-Agreement-20.pdf",
    label: "Get Form"
  },
  "Cash": {
    icon: <MoneyIcon sx={{fontSize: 'inherit'}} />,
    link: "https://home.paynearme.com/locations/",
    info: <>Pay Cash at 7/11, CVS and Walmart with a PayNearMe Barcode provided by Bayside Credit.</>,
    label: "Find Location"
  },
  "Phone": {
    icon: <PhoneIcon sx={{fontSize: 'inherit'}} />,
    info:  <div>Pay with a Check or Debit Card by phone:
          <br /><strong>949-519-0017</strong>
          <br /><strong>Toll Free: 877-254-9409</strong></div>,
    link: "tel: 949-519-0017",
    label: ""
  },
  "Mail": {
    icon: <MailIcon sx={{fontSize: 'inherit'}} />,
    info: <>Mail us at:<br /><strong>Bayside Credit<br />P.O Box 10847<br />Newport Beach, CA 92658</strong></>,
    link: "",
    label: ""
  }
}
