import * as React from 'react';
import { useFormContext, Controller } from "react-hook-form";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import FieldsGenerator from '../../form-components/FieldsGenerator';
import StepWatcher from 'components/forms/form-input-types/reacthookform/StepWatcher';
import FieldLabel from './FieldLabel';



export default function StepCheckboxGroupInput ({name, label, options, defaultValue, step, control, register, required, rules,...props}) {
  const { getValues, setValue } = useFormContext();
  const values = getValues();
    return (
        <FormGroup>
          <FieldLabel label={label} required={required} />
                  {
                  options.map((option: string, i)=>{
                    const option_key = `${name}.${option}`
                      return(
                        <>
                        <FormControlLabel control={
                          <Controller
                          name={option_key}
                          control={control}
                          rules={rules}
                          render={(props) => (
                            <Checkbox
                              required={required}
                              checked={props.field.value}
                              onChange={(e) => props.field.onChange(e.target.checked)}
                              defaultChecked={defaultValue[option]}
                            />
                          )}
                        />
                            } label={option} />
                          {
                          step &&
                          <StepWatcher register={register} control={control}
                          watchName={option_key} triggerValue={true}
                          stepItem={<FieldsGenerator meta={(step||{})[option]||[]} register={register} control={control} />}
                          triggerKey={option} step={step} />
                          }
                        </>
                      );
                  })
                  }
          </FormGroup>
    );
}
