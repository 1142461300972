import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//elements
import ImageDisplay from "components/elements/ImageDisplay";
import BlackFilter from 'components/filters/BlackFilter';


// window size


const AboutUsMap = {
  'Our Mission': {
    img: process.env.PUBLIC_URL+"/img/dashboard1.jpg",
    content: <Typography align="justify">To provide competitive and convenient financing solutions to auto dealers and their customers.</Typography>
  },
  'Our Vision': {
    img: process.env.PUBLIC_URL+"/img/car3.jpg",
    content: <Typography align="justify">To distinguish Bayside Credit as the premier solution for automotive financing in the  United States; providing a world class user experience through innovative solutions and industry leading customer service.</Typography>
  },
  'Our Values': {
    img: process.env.PUBLIC_URL+"/img/driving4.jpg",
    content:<Stack sx={{alignItems: 'center'}}>
  <Typography>Integrity</Typography>
  <Typography>Discipline</Typography>
  <Typography>Excellence</Typography>
  <Typography>Resilience</Typography>
  <Typography>Innovation</Typography>
  <Typography>Compassion</Typography>
  </Stack>}
}


const AboutUs2 = ()=> {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const titleBorders = isMobile ? "──": "──────";
  
  return(<Stack sx={{width: '100%', mb: 10}}>

  <Typography variant="h4" sx={{textTransform: 'capitalize', mb: 4}}>About Us</Typography>
  <Stack sx={{alignItems: 'center', border: '0px solid red', width: '100%'}}>
  {
    Object.entries(AboutUsMap).map(([k, v])=> {
      return(
        <Box sx={{
        m: 10,
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        backgroundImage: `url(${v.img})`,
        zIndex: 0,
        borderRadius: 100,
        width: {xs: '50vw', sm: '40vh'}, height: {xs: '50vw', sm: '40vh'}, maxWidth: '400px', maxHeight: '400px',
          }}>
          <BlackFilter strength={0.6} zIndex={1} borderRadius={200} />

          <Stack
            sx={{
              m: 5,
              width: '100%',
              height: '15vh',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '0px solid red',
              color: '#FFF',
              zIndex: 2}}>
          <Typography sx={{mt: 5, fontWeight: 'bold'}}>{titleBorders}&nbsp;&nbsp;&nbsp; {k} &nbsp;&nbsp;&nbsp;{titleBorders}</Typography>
          <Typography sx={{mt: 2, mb: 5, maxWidth: '500px', zIndex: 5}}>
            {v.content}
          </Typography>
          </Stack>
      </Box>
      )
    })
  }
  </Stack>
</Stack>);
};
export default AboutUs2;
  