import React from "react";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';

type NavLinkProps = {
  page: {
    name: string,
    href: string,
    value: string
  }
}

const section1 = [
{name: 'Payments', href: '/index/customers', value: "customers",},
{name: 'FAQ', href: '/index/faq', value: "faq",},
//{name: 'About Us', href: '/aboutus', value: "",},
];
const section2 = [
{name: 'Privacy Notice', href: process.env.PUBLIC_URL + '/docs/online_privacy_statement.pdf', value: ""},
{name: 'SMS/MMS Privacy Notice', href: process.env.PUBLIC_URL + '/docs/SMS and MMS Policy.pdf', value: ""},
{name: 'Consumer Privacy Notice', href: process.env.PUBLIC_URL + '/docs/bayside_consumer_notice.pdf', value: ""},
{name: 'CCPA Privacy Notice', href: process.env.PUBLIC_URL + '/docs/Bayside - CCPADisclosureCA.pdf', value: ""},
];
const pages = [section1, section2];

const textLogoUrl = process.env.PUBLIC_URL + '/BaysideLogo.png';

export default function Footer(props: any) {
  const StyledLink = styled('a')(({ theme }) => ({
          color: '#DDD',
          textDecoration: 'none',
          '&:hover': {
              color: '#FFF!important',
              textDecoration: 'none'
          },
          '&:visited': {
              color: '#DDD',
          },
      }));
  const StyledNavButton: any = ({page, handlePage, ...props}: any) => {
    const isExternalUrl = page.href.startsWith("http");
    return(
      <StyledLink
          component={isExternalUrl? "a" : Link}
          to={page.href}
          href={page.href}
          target={isExternalUrl ? "_blank" : ""}
          onClick={isExternalUrl ? void(0) : (e) => handlePage(page.value)}
          key={page.name}
        ><Typography className="footerLinkText">
           {page.name}
           </Typography>
      </StyledLink>
    );
  };

  return (
    <Container sx={{minWidth: '100%', maxWidth: '100%', backgroundColor: '#333',
                    color: '#FFF', display: 'inline-flex', justifyContent: 'center'}}>
  <Stack sx={{width: '100%', display: 'inline-flex', alignItems: 'flex-end',}}>
    <Box sx={{pt: 5, width: '100%',
        display: 'inline-flex', justifyContent: 'space-around', alignItems: 'flex-start', }}>

    <Box sx={{display: {xs: 'none', sm: 'block'}}}><img src={textLogoUrl} width={200} alt="" /></Box>
    <Box sx={{width: {xs: '100%', md: '40%', lg: '30%'}, display: 'flex', justifyContent: 'space-evenly'}}>
      {
        pages.map((section)=>{
          return(
            <Stack sx={{mb: 1,}}>
            {
              section.map((item)=>{
                return(<StyledNavButton page={item} handlePage={props.handlePage} />);
              })
            }
            </Stack>
          );
        })
      }
    </Box>
    </Box>
    <Typography sx={{mt: 2, mr: 5, mb: 5,
                    textTransform: 'capitalize', textAlign: 'right',
                    color: '#999', fontSize: '0.8em', }}>
    © 2022 Bayside Credit. <br />All rights reserved.
    <br />California Financing Law License Number: Application Pending
    </Typography>
    </Stack>
    </Container>
  );
}
