import * as React from 'react';
import Box from '@mui/material/Box';


type ImgProps = {
  url: string,
  backgroundPosition?: string,
  boxShadow?: number,
  borderRadius?: number
}

export default function ImgDisplay(props: ImgProps) {
  const parts = props.url.split(".")
  if(parts[parts.length-1] === "mp4") {
    return (
      <video width="100%" height="100%" loop autoPlay><source src={props.url} type="video/mp4"/></video>
    );
  }
  else {
    return (
      <Box sx={{width: "100%", height: "100%", boxShadow: props.boxShadow, borderRadius: props.borderRadius,
                backgroundImage: `url("${props.url}")`,
                backgroundSize: 'cover', backgroundPosition: props.backgroundPosition||'center 40%',}} />
    );
  }
}
