import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

//components
import BlackFilter from "components/filters/BlackFilter"
//import GradientBlueFilter from "components/filters/GradientBlueFilter"

//css
import '../../assets/scss/layout/homeheader.scss';


type Props = {
  pageRef: any,
  theme: {img: string, height: string}
}
type Filter = {
  strength: number,
}


export default function Header(props: Props) {  
  
  return (
    <Box id="homeheader" ref={props.pageRef} sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: props.theme.height,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        backgroundImage: `url(${props.theme.img})`,
        zIndex: -1,
      }}>
      <BlackFilter strength={0.2} />

      <Stack className="headerTextOutlined"
        sx={{
          width: '100%',
          height: '15vh',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '30vh',
          border: '0px solid red',
          zIndex: 1}}>
      <Typography align="center" sx={{fontSize: {xs: '2.8em', sm: '4em', xl: "8rem"}}}> Bayside Credit </Typography>
      <Typography align="center" sx={{lineHeight: 0.5, fontSize: {xs: '1.3em', sm: '2em', xl: "5rem"},}}>
        Leaders in Automotive Finance
        </Typography>
      </Stack>
  </Box>
  );
}
