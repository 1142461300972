
export const FAQCustomers = {
  "Account Servicing": [{
    title: "How do I contact a live Bayside Credit representative?",
    body: "You can reach a Bayside Credit representative at 949-519-0017 Monday through Friday from 9:00 AM to 6:00PM PST."
  }, {
    title: "Who do I contact if I am a new customer and my contract is still being processed?",
    body: "Please reach out to our Funding Department at 949-266-9036. You can reach them Monday through Friday from 9:00 AM to 6:00 PM PST."
  }, {
    title: "How do I view my recent payment(s)?",
    body: "You can access your payment history via your customer portal or call us at 949-519-0017."
  },
  {
    title: "How do I get a current payoff of my loan?",
    body: "You can access your payoff via your customer portal or call us at 949-519-0017."
  }, {
    title: "Where can I mail my payment?",
    body: <>Bayside Credit<br />P.O Box 10847<br />Newport Beach, CA 92658</>
  }, {
    title: "What payment methods are accepted?",
    body: <>Debit online - <a href='https://www.paynearme.com/baysidecredit'>One-time payment</a> or <a href='https://baysidecredit.accountportalonline.com/'>account portal</a> (log in or create an account)
    <br />ACH/Check online - <a href='https://www.paynearme.com/baysidecredit'>One-time payment</a> or <a href='https://baysidecredit.accountportalonline.com/'>account portal</a> (log in or create an account)
    <br />Auto Pay online - <a href='docs/Autopay-Agreement-20.pdf'>Set up automatic ACH payments</a> (restrictions apply)
    <br />Phone - Call us at 949-519-0017 and select option 1 (automated IVR)
    <br />Cash - <a href='https://home.paynearme.com/locations/'>Pay via PayNearMe at 7/11, CVS, Walmart</a> (barcode required from statement or agent SMS text)
    </>
  }
  ],
  "Credit Reporting": [
    {
      title: "What credit bureaus does Bayside Credit report to?",
      body: "We report to Experian, Equifax, and TransUnion."
    },
    {
      title: "How often does Bayside Credit report to the credit bureaus?",
      body: "Bayside Credit reports to the bureaus at the end of every month, but it takes an additional 30 to 60 days for the bureau to reflect the changes."
    },
    {
      title: "Why do I still see an open loan for Bayside Credit on my credit report even though my loan has been paid off?",
      body: "It can take up to 60 days for changes to your account to be reflected in your credit report."
    }
  ],
  "Insurance": [
   {
     title: "Why do I need full coverage insurance?",
     body: "Full coverage insurance is required to better protect you in the case of a total loss. If your car is totaled, you are still obligated to pay off the remainder of your loan."
   },
   {
     title: "Why does Bayside Credit need to be listed as lienholder?",
     body: "Bayside Credit is the lender of your unit and holds interest on the property until the loan is fully paid off."
   },
   {
     title: "What is the deductible amount I need to have on my policy?",
     body: "Deductibles for comprehension and collision must be $1,000 or below."
   }
 ]
  ,
  "Other": [
    {
      title: "How do I contact a Bayside Credit representative?",
      body: "You can reach a Bayside Credit representative at 949-284-8818 Monday through Friday from 9:00AM to 6:00PM PST."
    },
    {
      title: "Who do I contact if I am a new customer and my contract is still being processed?",
      body: "Please reach out to our Funding Department at 949-266-9036. You can reach them Monday through Friday from 9:00 AM to 6:00 PM PST."
    },
    {
      title: "How do I go about making payment?",
      body: <>Debit online - <a href='https://www.paynearme.com/baysidecredit'>One-time payment</a> or <a href='https://baysidecredit.accountportalonline.com/'>account portal</a> (log in or create an account)
              <br />ACH/Check online - <a href='https://www.paynearme.com/baysidecredit'>One-time payment</a> or <a href='https://baysidecredit.accountportalonline.com/'>account portal</a> (log in or create an account)
              <br />Auto Pay online - <a href='docs/Autopay-Agreement-20.pdf'>Set up automatic ACH payments</a> (restrictions apply)
              <br />Phone - Call us at 949-519-0017 and select option 1 (automated IVR)
              <br />Cash - <a href='https://home.paynearme.com/locations/'>Pay via PayNearMe at 7/11, CVS, Walmart</a> (barcode required from statement or agent SMS text)
              </>
    },
  ],
  "Title Registration": [
    {
      title: "How long does it take to receive my title after a pay off?",
      body: "It can take approximately 4 to 6 weeks for the DMV to release your title. Due to the DMV being impacted from COVID-19, actual times may very."
    },
    {
      title: "How do I register my vehicle in a new state after I move?",
      body: "The process is different depending on which state you are moving to. Please contact your new DMV and they will assist you with the process."
    }
  ]
}
