export const FedexContent = {
    "bestpractices": {
      title: "Fedex Best Practices",
      listTitle: "Tips to Speed Up Processing Time:",
      listType: "ordered",
      body: [
        "Place the correct approval page on top of each package deal.",
        "Make sure the approval matches the approved & contracted deal.",
        "Only 1 staple per contract package",
        "Only 1 contract package per staple package.",
        "Max of 5 contracts per FedEx shipment",
        "Only send deals intended for Bayside Credit",
        "Remove all duplicate carbons."
        ]
      },
  }
  