import {useState, useEffect, useRef} from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import {
   StyledEngineProvider
} from '@mui/material';

// elements
import AltPage from "components/layouts/AltPage"
import ContentDisplaySimple from "components/elements/ContentDisplaySimple"
import LoadingDisplay from "components/elements/LoadingDisplay";

// styling
import "assets/scss/pages/participationgrid.scss";


const url = process.env.REACT_APP_API_URL+"/data/participation/";
const imgUrl = process.env.PUBLIC_URL + "/img/documents2.jpg";
const today = new Date();


export default function ParticipationGrid() {
  const {id, authkey} = useParams();
  const [applicationId, setApplicationId] = useState(null);
  const [gridData, setGridData] = useState(<LoadingDisplay />);
  const [decisionDate, setDecisionDate] = useState(new Date(""));
  const [expirationDate, setExpirationDate] = useState(new Date(""));
  const [numCols, setNumCols] = useState(0);
  const [numRows, setNumRows] = useState(0);

  // Ref to track initial render
  const isInitialRender = useRef(true);

  useEffect(()=>{
    async function getData(id: string) {
      try {
        const { data } = await axios.get(`${url}${authkey}`);
        setApplicationId(data['application_id']);
        setDecisionDate(new Date(data['decision_date']));
        setExpirationDate(new Date(data['expiration_date']));
        setGridData(<div dangerouslySetInnerHTML={{ __html: data["grid_data"] }} />);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          //handleAxiosError(error);
          console.log("Some error handling... try again?")
        } else {
          //handleUnexpectedError(error);
        }
      }
    }

    // Delay subsequent renders
    const delay = isInitialRender.current == true ? 0 : 600000; // render delay in milliseconds
    setTimeout(() => {getData(id as string);}, delay);
    isInitialRender.current = false;
    
    let numCols = document.getElementsByTagName('table')[0]?.rows[0]?.cells?.length || 0;
    setNumCols(numCols);
    let numRows = document.getElementsByTagName('table')[0]?.rows?.length || 0;
    setNumRows(numRows);
  }, [id, gridData, authkey]);

  function ShowDate() {
    if (!isNaN(decisionDate.getTime())) {
      return(
        <><br />Decision Date: {decisionDate.toLocaleDateString("en-US")}
        <br />{(today < expirationDate) ? `Expires on ${expirationDate.toLocaleDateString("en-US")}` : "Expired"}</>
      );
    }
    else {
      return (<></>);
    }
  }

  function PGBody() {
    return(
      <StyledEngineProvider injectFirst>
              Application Id: {applicationId}
              <ShowDate />
              <br /><br />
              <Box className={`participation ${numCols > 1 ? "participation-header participation-color-odd" : "participation-color-even participation-hover-all"}`}>{gridData}</Box>
              <br /><br />
              <a href={`/fedex/label/${id}/${authkey}`}>Print FedEx Labels</a>
              <br /><br />
              <a href="https://portal.passtimegps.com/bayside" target="_blank">Click here to order GPS unit</a>
            </StyledEngineProvider>
    );
  }

  return (
    <AltPage useDefaultNavbar={false} img={imgUrl} content={<ContentDisplaySimple
                          img={imgUrl}
                          width={{xs: '90vw', md: '60vw', lg: '60vw'}}
                          title={numRows === 1 ? "Funding Breakdown" : "Contract Options"}
                          body={<PGBody />} />} />
  );
}
