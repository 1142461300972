import React from "react";
import Box from '@mui/material/Box';

//components
import BlackFilter from "components/filters/BlackFilter"
import GradientBlueFilter from "components/filters/GradientBlueFilter"


type Props = {
  img: string,
  height: number | string,
}

export default function Background(props: Props) {
  return (
    <Box id="data-bg" sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: props.height,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        backgroundImage: `url(${props.img})`,
        zIndex: -2,
        p: 10,
      }}>
      <BlackFilter strength={0.4} />
      <GradientBlueFilter strength={0.6} />
    </Box>
  );
}
