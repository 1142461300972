import * as React from 'react';
import InputAdornment from '@mui/material/InputAdornment';

//input types
// elements
import RHFAddressInput from 'components/forms/form-input-types/reacthookform/RHFAddressInput';
import RHFSelectInput from 'components/forms/form-input-types/reacthookform/RHFSelectInput';
import RHFTextInput from './RHFTextInput';
import RHFCheckboxInput from './RHFCheckboxInput';
import RHFACHPaymentInput from './RHFACHPaymentInput';
import RHFMaskedInput from './RHFMaskedInput';


export default function RHFDynamicInput({field_type, ...props}) {

    let fieldTypes = {
        'checkbox': <RHFCheckboxInput {...props} />,
        'dropdown': <RHFSelectInput {...props} />,
        'address': <RHFAddressInput {...props} />,
        'ach': <RHFACHPaymentInput {...props} />,
        'ssn': <RHFMaskedInput mask={'999-99-9999'} {...props} />,
        'taxid': <RHFMaskedInput mask={'99-9999999'} {...props} />,
        'tel': <RHFMaskedInput mask={'(999) 999-9999'} {...props} />,
        'number': <RHFTextInput inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', max: 3, }} {...props} />,
        'date': <RHFTextInput type="date" {...props} />,
        'currency': <RHFTextInput InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}} {...props} />,
        'percent': <RHFTextInput InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>, }} {...props} />
    }
    let defaultField = <RHFTextInput {...props} />;
    let field = field_type in fieldTypes ? fieldTypes[field_type] : defaultField;
    return field;
}